<template>
    <div class="w-100 m-0 d-flex justify-content-center position-relative">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <div class="row">
                <div class="col">
                    <h1 class="text-white fw-normal display-6 title">Ziekmelding toegevoegd</h1>
                    <hr/>
                    <p class="text-dark bg-info p-3 small text-start rounded-2"><i class="fa fa-heart me-1"></i> Bedankt
                        voor je
                        ziekmelding. We wensen je veel beterschap tijdens je herstelproces.</p>
                    <div class="text-light bg-trans p-3 small text-start rounded-2"><i
                            class="fa fa-info-circle me-1"></i>
                        Het overkomt je, je raakt (gedeeltelijk) arbeidsongeschikt. Wat nu?
                        <hr />
                        <h4 class="mt-3 fw-normal">Lees onderstaand stappenplan goed door.</h4>
                        <ol class="mt-4">
                            <li>Binnen één week nemen wij contact met je op om te vragen hoe het met je gaat. We stellen je
                                in
                                dit gesprek een aantal triagevragen om je ziekmelding te kunnen categoriseren voor de
                                bedrijfsarts.
                            </li>
                            <li>Na de triage melden wij je aan bij een onafhankelijke arbodienst. Deze arbodienst heeft jouw
                                medische gegevens – voor zover relevant voor je ziekmelding - nodig. Deze dien je zelf op te
                                vragen bij je (huis)arts. De arbodienst stuurt je hiertoe een mail met de benodigde
                                formulieren.
                            </li>
                            <li>Dit medische dossier moet je dus zelf regelen. Op het moment dat een arbodienst je dossier
                                moet
                                opvragen bij je (huis)arts, dan zijn hier kosten aan verbonden die voor jouw rekening zijn.
                                Zolang je je medische gegevens niet hebt overhandigd aan de arbodienst, word je niet
                                uitgenodigd
                                voor een consult, kan je ziektebeeld niet worden vastgesteld en ontvang je geen schenking.
                            </li>
                            <li>De arbodienst nodigt je uit voor een spreekuurconsult en geeft een prognose af. Ook stelt de
                                arbodienst vast of je ziekmelding niet al voor deelname te verwachten was. Dit kan veelal
                                worden
                                opgemaakt uit je medisch dossier. Wij worden hiervan op de hoogte gesteld.
                            </li>
                            <li>Na twee maanden wachttijd en de vaststelling van je ziekte door de arbodienst, ontvang je
                                vanaf
                                de eerstvolgende maand je schenking.
                            </li>
                            <li>Je ziektepercentage is altijd maximaal het percentage dat door de arbodienst wordt
                                vastgesteld.
                                Als je tussentijds meer arbeidsongeschikt raakt, dan moet dat eerst door de arbodienst
                                worden
                                vastgesteld voordat het percentage wordt aangepast.
                            </li>
                            <li>Een tussentijdse verlaging van je arbeidsongeschiktheidspercentage kun je zelf aan ons
                                doorgeven. Ga je (deels) weer aan het werk geef dan direct (via het formulier betermelding)
                                door
                                dat je deels hersteld bent.
                            </li>
                            <li>Als de arbodienst van mening is dat je je na een bepaalde periode weer moet melden, wordt er
                                een
                                nieuwe afspraak met je ingepland. De arbodienst koppelt altijd alles terug aan jou en aan
                                ons.
                                Wij zijn dus altijd op de hoogte van de status van je ziekmelding.
                            </li>
                            <li>Zodra je weer (deels) in staat bent om te werken, meld je dat via het formulier
                                “betermelding”
                                of neem je telefonisch contact met ons op.
                            </li>
                            <li>Je bent verplicht mee te werken aan alle aangeboden re-integratie activiteiten.
                            </li>
                            <li>Indien je niet verschijnt op de afspraak bij de arbodienst, je niet meewerkt aan
                                re-intgratie
                                naar passend werk of anderszins gemaakte afspraken niet nakomt, zullen wij je schenking
                                stopzetten dan wel opschorten.
                            </li>
                            <li>Indien je een aanvullende AOV tot AOW hebt afgesloten dien je je ook direct ziek te melden
                                bij
                                Insify. Let op: dit is je eigen verantwoordelijkheid.
                            </li>
                        </ol>
                        <router-link to="/disability" class="btn btn-primary"><i class="fa fa-arrow-left me-1"></i> Terug
                            naar overzicht</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
h6 {
    font-weight: 800;
}

input[type="radio"] {
    transform: scale(1.5);
}

p {
    text-align: right;
}

li {
    margin-bottom: 2em;
}

.tl {
    text-align: left !important;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
    text-align: left;
}
</style>

<script type="text/javascript">
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { Breadcrumb, updateAppearanceKnab, isKnab } from '@/App.vue';

export default {
    name: "disability-confirmation",
    components: {
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/disability', 'Mijn ziekmeldingen'), new Breadcrumb(`/disability/update/${this.$route.params.id}`, 'Ziekmelding'), new Breadcrumb('/disability-confirmation', 'Ziekmelding bevestiging')]
        }
    },
    mounted() {
        if(isKnab()) {
            updateAppearanceKnab();
        }
    }
}
</script>
