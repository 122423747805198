<template>
    <div class="w-100 m-0 d-flex justify-content-center min-full-page">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h3 class="display-4 text-white fw-normal title">Vangnetniveau wijzigen</h3>
            <hr/>
            <table class="table mb-0 position-relative mt-3 new-brand">
                <thead>
                    <tr>
                        <th class="text-white">
                            <span class="d-none d-md-block">Vangnetniveau</span>
                            <span class="d-md-none">Vangnetniv.</span>
                        </th>
                        <th class="text-white">
                            <span class="fw-normal">Inleg</span>
                        </th>
                        <th class="text-white fw-normal">
                            <span class="d-none d-md-block fw-normal">Administratiekosten</span>
                            <span class="d-md-none fw-normal">Admin.</span>
                        </th>
                        <th class="text-white fw-normal text-end">
                            <span class="fw-normal">Totaal</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="subgroup in dynamic.aov_subgroups" :key="subgroup.id">
                        <tr :class="['subgroup', subgroup.id !== showAovSubgroup.id ? 'inactive' : 'current-brand', !chosenPlanIsValid(subgroup) ? 'not-available' : '']" @click="() => changeCurrentBrand(subgroup)">
                            <td class="p-3">{{ $numberFormatEuro(subgroup?.subgroup_payout_monthly ?? 0, 0) }}</td>
                            <td class="p-3">
                                {{ $numberFormatEuro(subgroup?.expected_monthly_costs - subgroup?.monthly_costs ?? 0, 0) }}
                            </td>
                            <td class="p-3">{{ $numberFormatEuro(subgroup?.monthly_costs ?? 0, 2) }}</td>
                            <td class="p-3 text-end">{{ $numberFormatEuro(subgroup?.expected_monthly_costs ?? 0, 2) }}</td>
                        </tr>
                        <tr :class="['d-none d-md-table-row current-brand-info not-current subgroup-info-' + subgroup.id]">
                            <td class="bg-light p-0">
                                <div :class="['overflow-hidden', subgroup.id === showAovSubgroup.id ? 'show' : 'collapse-crowdsurance']">
                                    <p class="p-3 m-0">
                                        <small>
                                            <div>Inkomen bij ziekte (2 jaar)</div>
                                            <h3 class="mt-2 h2">{{ $numberFormatEuro(subgroup?.subgroup_payout_monthly ?? 0, 0) }}</h3>
                                            <div>netto per maand</div>
                                        </small>
                                    </p>
                                </div>
                            </td>
                            <td class="bg-light p-0">
                                <div :class="['overflow-hidden', subgroup.id === showAovSubgroup.id ? 'show' : 'collapse']">
                                    <p class="p-3 m-0">
                                        {{ $numberFormatEuro(subgroup?.expected_monthly_costs - subgroup?.monthly_costs ?? 0, 0) }} p/m
                                    </p>
                                </div>
                            </td>
                            <td class="bg-light p-0">
                                <div :class="['overflow-hidden', subgroup.id === showAovSubgroup.id ? 'show' : 'collapse']">
                                    <p class="p-3 m-0">
                                        {{ $numberFormatEuro(subgroup?.monthly_costs ?? 0, 2) }} p/m<br/>
                                        <small class="small-italic">Inclusief BTW</small>
                                    </p>
                                </div>
                            </td>
                            <td class="bg-light p-0 text-end">
                                <div :class="['overflow-hidden', subgroup.id === showAovSubgroup.id ? 'show' : 'collapse']">
                                    <p class="p-3 m-0">
                                        {{ $numberFormatEuro(subgroup?.expected_monthly_costs ?? 0, 2) }} p/m
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr :class="['d-table-row d-md-none current-brand-info not-current subgroup-info-' + subgroup.id]" v-if="showAovSubgroup.id == subgroup.id">
                            <td colspan="4" class="bg-light p-0 border-0">
                                <div :class="['overflow-hidden', subgroup.id === showAovSubgroup.id ? 'show' : 'collapse']">
                                    <p class="p-3 m-0 pb-0">
                                        <small>
                                            <div>Inclusief vangnetkosten</div>
                                            <div>Inkomen bij ziekte (2 jaar)</div>
                                            <h3 class="mt-2 h2 mb-0">{{ $numberFormatEuro(subgroup?.subgroup_payout_monthly ?? 0, 0) }}</h3>
                                        </small>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr :class="['d-table-row d-md-none current-brand-info not-current subgroup-info-' + subgroup.id]" v-if="showAovSubgroup.id == subgroup.id">
                            <td colspan="2" class="bg-light p-0">
                                <small class="small-italics">
                                    <div class="px-3 py-1 m-0">netto per maand</div>
                                </small>
                            </td>
                            <td colspan="2" class="bg-light p-0">
                                <small class="small-italics">
                                    <div class="px-3 py-1 m-0">Incl. BTW</div>
                                </small>
                            </td>
                        </tr>
                        <tr :class="['d-table-row d-md-none current-brand-info not-current subgroup-info-' + subgroup.id]" v-if="showAovSubgroup.id == subgroup.id">
                            <td class="p-3 small">
                                &euro; per maand
                            </td>
                            <td class="p-3 small">
                                {{ $numberFormatEuro(subgroup?.expected_monthly_costs - subgroup?.monthly_costs ?? 0, 0) }}
                            </td>
                            <td class="p-3 small">
                                {{ $numberFormatEuro(subgroup?.monthly_costs ?? 0, 2) }}
                            </td>
                            <td class="fw-bold p-3 small text-end">
                                {{ $numberFormatEuro(subgroup?.expected_monthly_costs ?? 0, 2) }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="bg-block subtle-border w-100 position-relative">
                <h6 class="text-dark fw-bold text-center py-3 px-3 pb-1">Kies de datum waarop je gekozen vangnetniveau ingaat</h6>
                <div class="row row-cols-1">
                    <div class="col px-5 mt-1 mb-2">
                        <select class="form-select" id="dateStart" v-model="change_at">
                            <option v-for="date in changeAtOptions" :key="date" :value="date">
                                {{ displayStartDate(date) }}
                            </option>
                        </select>
                    </div>

                    <div class="col px-5 mb-3">
                        <div :class="['btn btn-primary', !chosenPlanIsValid(showAovSubgroup) ? 'opacity-50' : '']" @click="submitChanges()" :key="showAovSubgroup"><i class="fas fa-paper-plane me-1"></i> Bevestig mijn keuze</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Modal v-for="modal in modals" :key="modal.title" :title="modal.title" :content="modal.content"
        :button_text="modal.button_text" :button_icon="modal.button_icon" :url="modal.url" :modal_type="modal.modal_type" :question_enum="modal.question_enum" :additional_class="modal.additional_class"
        @closeModal="closeModal" @storeNewProducts="storeNewProducts" />
</template>

<script type="text/javascript">
/* eslint-disable*/

import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import { getCustomerStorage, isKnab, updateAppearanceKnab } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Modal from '@/components/ModalComponent.vue';
import { useToast } from 'vue-toastification';

export default {
    name: "products-change-page",
    components: {
        Breadcrumbs,
        Modal,
    },
    data() {
        return {
            brand_levels: [],
            current_brand_id: -1,
            breadcrumbs: [new Breadcrumb('/products-crowdsurance', 'Mijn crowdsurance'), new Breadcrumb('/products-crowdsurance/change', 'Vangnetniveau wijzigen')],

            dynamic: {},
            now: new Date(new Date().setHours(0,0,0,0)),
            change_at: 0,
            changeAtOptions: [],
            selected: new Date(new Date().setHours(0,0,0,0)),
            changes: [],

            showPaymentSubgroup: null,
            showPaymentSubgroupIndex: 0,

            showAovSubgroup: null,
            currentAovSubgroup: null,
            showAovSubgroupIndex: 0,

            aov_subgroups: [],
            payment_subgroups: [],
            modals: [],
            originalDate: null,

            customer: {},
            product_error: {
                title: "Vangnetniveau niet beschikbaar",
                body: "Je kunt dit vangnetniveau niet kiezen omdat deze ingaat of al is ingegaan op de geselecteerde datum. Kies een ander vangnetniveau.",
            }
        }
    },
    methods: {
        toggleChange(change) {
            this.showPaymentSubgroup = null;
            this.showPaymentSubgroupIndex = 0;
            
            this.showAovSubgroup = null;
            this.showAovSubgroupIndex = 0;

            this.dynamic.participants.forEach(function(participant) {
                const start_at = new Date(participant.start_at),
                    end_at = participant.end_at ? new Date(participant.end_at) : null;

                if(start_at <= change && (!end_at || end_at >= change)) {
                    
                    this.payment_subgroups.forEach(function(subgroup, subgroupIndex) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showPaymentSubgroup = subgroup;
                            this.showPaymentSubgroupIndex = subgroupIndex;

                            if(participant.active_payout_requests.length) {
                                participant.active_payout_requests.forEach(function(payout_request) {
                                    if(payout_request.type == 'payout_after_directdebit_sure' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_directdebit_amount = payout_request.amount;
                                    } else if(payout_request.type == 'payout_after_payment' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_payment_amount = payout_request.amount;
                                    } else if(payout_request.type == 'savings_during_payment' && payout_request.user_iban == null) {
                                        this.show_savings_during_payment_amount = payout_request.amount;
                                    }

                                }.bind(this));
                            }
                        }
                    }.bind(this));

                    this.aov_subgroups.forEach(function(subgroup, subgroupIndex) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showAovSubgroup = subgroup;
                            this.showAovSubgroupIndex = subgroupIndex;
                        }
                    }.bind(this));
                }
            }.bind(this));

        },

        changeCurrentBrand(subgroup) {
            this.showAovSubgroup = subgroup;
        },

        chosenPlanIsValid(plan) {
            // Controleren of er überhaupt deelnames zijn: anders allemaal aanzetten
            if(!this.dynamic.participants)
                return true;

            for(let i = 0; i < this.dynamic.participants.length; i++) {
                let participant = this.dynamic.participants[i];
                if(!participant.subgroup)
                    continue;
                if(new Date(participant.start_at).getTime() === new Date(this.change_at).getTime() && participant.subgroup.id === plan.id) {
                    return false;
                }
            }

            // Ook controleren of laatste deelname het huidige product is
            let latestProduct = this.dynamic.participants.at(-1);

            if(!latestProduct)
                return true;

            return latestProduct.subgroup.id !== plan.id;
        },

        displayStartDate(date) {
            let d = new Date(date);
            let months = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
            
            return d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear();
        },

        closeModal() {
            this.modals[0].additional_class = 'disappear';
            setTimeout(() => {
                this.modals = [];
            }, 500);
        },

        showErrorModal(unavailableProduct = false) {
            this.modals = [];
            this.modals.push(this.$createModal(unavailableProduct ? "Product niet beschikbaar voor inleg" : "Inleg te laag", "Verhoog je maandelijkse inleg of kies een ander product om deze productsamenstelling te kiezen.", "fa-check", "OK", "/", this.$ModalType.Notification));
        },

        submitChanges() {
            if(!this.chosenPlanIsValid(this.showAovSubgroup)) {
                this.modals = [];
                this.modals.push(this.$createModal(this.product_error.title, this.product_error.body, "fa-times", "Sluiten", "/products", this.$ModalType.Notification));
                return;
            }
            let hasChangesFromSelectedChangeAt = false;
            this.changes.forEach(function(change) {
                if(+change >= +(new Date(this.change_at))) {
                    hasChangesFromSelectedChangeAt = true;
                }
            }.bind(this));

            // Laat een modal zien om de gebruiker te vragen om de gekozen producten toe te passen
            let title = hasChangesFromSelectedChangeAt ? "Let op!" : "Vangnetniveau wijzigen";
            let message = hasChangesFromSelectedChangeAt ? "Er zijn al eerder wijzigingen gekozen vanaf de geselecteerde datum. Wil je deze vervangen?" : "Weet je zeker dat je dit vangnetniveau wilt hanteren?";
            this.modals.push(this.$createModal(title, message, "fa-check", "Ja", "/products", this.$ModalType.Question, "storeNewProducts"));
        },

        storeNewProducts() {
            this.$http.post('/participants/dynamic', {
                    change_at: (new Date(this.change_at)).toJSON(),
                    aov_subgroup_id: this.showAovSubgroup.id,
                    cov_subgroup_id: null,
                    payment_subgroup_id: this.showAovSubgroup.subgroup_payment_monthy.id,
                    retirement_after_directdebit_amount: 0,
                    retirement_after_payment_amount: 0,
                    savings_during_payment_amount: 0,
                }).
                then(() => {
                    this.$router.push('/products-crowdsurance');
                })
                .catch((err) => {
                    const toast = useToast();
                    toast.error(err);
                })
        }
    },
    async mounted() {
        if(isKnab()) {
            updateAppearanceKnab();
        }

        const response = await this.$http.get('/participants/dynamic');
        this.dynamic = response.data;

        setTimeout(() => {
            $(".subgroup").on('click', () => {
            });
        }, 1000);

        let customer = getCustomerStorage().getItem("customer");
        if(!customer) {
            console.log("Customer not found");
            return;
        }
        this.customer = JSON.parse(customer);

        this.now = new Date(new Date().setHours(0,0,0,0));
        this.change_at = +(new Date(this.dynamic.change_at));

        for(let i = 0; i < 4; i++) {
            let date = new Date(this.dynamic.change_at);
            date.setMonth(date.getMonth() + i)
            this.changeAtOptions.push(+date);
        }
        
        this.aov_subgroups = this.dynamic.aov_subgroups.filter(function(subgroup) {
            if(subgroup.end_at && new Date(subgroup.end_at) < this.change_at && !this.dynamic.disabled_now) {
                return false;
            } else {
                return true;
            }
        }.bind(this));

        // Koppel payment_groups aan AOV niveaus
        let payment_subgroups = this.dynamic.payment_subgroups;
        for(let i = 0; i < this.aov_subgroups.length; i++) {
            for(let j = 0; j < payment_subgroups.length; j++) {
                if(this.aov_subgroups[i].expected_monthly_costs === payment_subgroups[j].subgroup_payment_monthy) {
                    this.aov_subgroups[i].subgroup_payment_monthy = payment_subgroups[j];
                    break;
                }
            }
        }

        // STAP 1 breakpoinst bepalen
        this.changes = [this.now];
        this.dynamic.participants.forEach(function(participant) {
            const start_at = new Date(participant.start_at),
                end_at = participant.end_at ? new Date(participant.end_at) : null;

            // opv start, in toekomst, of niet lege end
            if(start_at > this.now) {
                let change = new Date(start_at);
                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }
            if(end_at) {
                let change = new Date(end_at);
                change.setDate(change.getDate() + 1);

                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }

        }.bind(this));

        this.originalDate = this.changeAtOptions[0];

        // STAP 1 huidig bepalen
        this.toggleChange(this.now);

        if((!this.showAovSubgroup || !this.showPaymentSubgroup) && this.changes.map(Number).indexOf(+new Date(this.dynamic.change_at)) !== -1) {
            this.toggleChange(new Date(this.dynamic.change_at));
        }
        if(!this.showAovSubgroup && this.aov_subgroups.length) {
            this.showAovSubgroupIndex = 0;
            this.showAovSubgroup = this.aov_subgroups[0];
        }

        this.currentAovSubgroup = this.showAovSubgroup;

        $("#dateStart").on('change', function() {
            let date = new Date(parseInt($("#dateStart").find(":selected").val()));
            $(".change-at").text([date.getDate() < 10 ? '0'+  date.getDate() : date.getDate(), date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1, date.getFullYear()].join("-"));
        });
    }
}
</script>

<style scoped>
.fa-minus,
.fa-plus {
    cursor: pointer;
}

h4 {
    transition: color var(--transition-400ms) ease;
}

.too-long {
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
}

.fa-chevron-down {
    font-size: 42px;
}

.selection-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.bg-block {
    background-color: #fff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05) inset;
}

.opacity-anim {
    transition: opacity var(--transition-400ms) ease;
}

.rot-180 {
    transform: rotateZ(180deg);
}

.show {
    animation: show-in 400ms ease-in-out forwards;
}

.collapse-crowdsurance {
    max-height: 0;
}

@keyframes show-in {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 150px;
    }
}

.svg-icon {
    height: 1em;
    color: #fff;
    margin-left: 0.5em;
    transition: transform 600ms;
    opacity: 80%;
}

.vertical-align {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 0;
    text-align: center;
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

.bg-gray {
  
  background-color: #ccc;
}

.big-btn {
    width: 1.5em;
    position: absolute;
    bottom: calc(50% - 0.75em);
}

hr {
    border-color: rgba(255, 255, 255, 0.2);
}

.bg-white, .bg-non-selectable {
    transition: background-color var(--transition-400ms) ease;
}

.bg-non-selectable {
    background-color: #ddd;
    cursor: default;
}

.text-dark-blue {
    color: var(--dark-blue);
}

.subtle-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.indicator {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 0;
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #fff;
}

.h5-product {
    color: var(--dark-blue) !important;
}

.icon-products-container {
    /* padding: 2em; */
    /* border-radius: 50%;
    background: linear-gradient(-45deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6));
    border: 1px dotted rgba(0, 26, 105, 0.3); */
}

.indicator-text {
    color: #fff;
    font-weight: thin !important;
    font-size: 10px;
    left: 20px;
    top: -21x;
    position: absolute;
    display: none;
}
table tr td,
table tr,
th {
    background-color: transparent;
}
.inactive > td {
    color: white;
}
.inactive:hover{
    cursor: pointer;
    opacity: 0.75;
}

.smaller {
    font-size: 11px;
}

h4 {
    font-size: calc(16px + 0.4vw);
}

.not-available > td {
    color: var(--crowdsurance-header) !important;
}
</style>
