<script setup>
import { RouterView } from 'vue-router';
import NavigationBar from '@/components/NavigationBarComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
</script>

<template>
  <div id="backdropPlanet"></div>
  <div id="backdropRain"></div>

  <div class="sticky">
    <NavigationBar />
  </div>

  <div class="content">
    <RouterView />
  </div>

  <div class="position-relative">
    <FooterComponent />
  </div>
</template>

<style>
/* Lettertypen */
@font-face {
  font-family: "Intro Regular";
  src: url(../public/fonts/Fontfabric\ -\ Intro-Regular.otf);
}

@font-face {
  font-family: "PT Sans";
  src: url(../public/fonts/PT_Sans-Web-Regular.ttf);
}

:root {
  /* Kleuren */
  --light-blue: #E8F4FA;
  --dark-blue: #042F79;
  --dark-blue-alt: rgb(31, 64, 122);
  --dark-blue-hover: #1e4d9e;
  --dark-blue-alt-hover: #2863c8;
  --warning: #ff413e;
  --light-green: #DDE7CB;
  --light-yellow: #fff0c8;

  --color-info: #00ccff;
  --color-danger: #ff413e;
  --color-warning: #ffc422;
  --color-success: #00ff9d;

  /* Crowdsurance */
  --crowdsurance-header: #003b5a;
  --crowdsurance-notification: #00a8ba;
  --crowdsurance-background: #f5fcfc;

  /* Animatie snelheden */
  --transition-200ms: 200ms;
  --transition-400ms: 400ms;

  /* Rounded corners */
  --block-rounded: 1px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

p {
  z-index: 32;
  position: relative;
}

#backdropPlanet {
  background-size: cover;
  background-attachment: fixed;
  background-image: url("assets/clean.jpg");
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#backdropRain {
  background-size: cover;
  /* background-attachment: fixed; */
  background-image: url("assets/violet_blur.jpg");
  opacity: 0.1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
}

.ideal {
    width: 1.5em;
    transform: translateY(-1px);
}

#app {
  font-family: "Lato", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.pagination {
  min-width: 1.33lh;
  text-align: center;
  transition: background-color var(--transition-200ms) ease-in-out, color var(--transition-400ms) ease-in-out, border-color var(--transition-400ms) ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.35) !important;
  border-right: 0 !important;
}

.border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.35) !important;
}

.pagination:hover {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #333 !important;
  cursor: pointer;
}

.pagination-current-active {
  color: #fff !important;
}

.float-right {
    position: absolute !important;
    right: 0;
    top: 0;
}

.toast-container {
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  max-width: 320px;
  /* backdrop-filter: blur(6px); */
}

.min-full-page {
  min-height: 100vh;
}

.container-inputs {
  width: 100%;
  max-width: 720px;
}

.btn {
  position: relative;
  z-index: 500;
}

.bg-green {
  background-color: var(--light-green);
}

.bg-yellow {
  background-color: var(--light-yellow);
}

.bg-blue:hover {
  background-color: var(--dark-blue-alt-hover);
  color: #fff !important;
}
.crowdsurance .bg-trans {
  background-color: #003b5a33 !important;
}

html,
body {
  margin: 0;
  padding: 0;
  background-size: cover;
  overflow-x: clip;
  z-index: -1024;
}

.backdrop-zo {
  background-image: linear-gradient(45deg, #1A3871, #4392D0);
}

.sticky-menu {
  position: fixed;
  top: 0;
  z-index: 512;
  transition: transform var(--transition-400ms) ease;
}

.hidden {
  transform: translateX(-100%);
}

.content {
  margin-top: 60px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 2em;
}

.inputs {
  overflow: hidden;
}

.breadcrumb-icon {
  margin-top: 3px;
}

.block {
  border: 1px solid rgba(255, 255, 255, 0.1);
  aspect-ratio: 1/1;
  color: #1A3871;
  height: 0;
  box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.3);
  min-height: 180px;
  background: #fff;
  position: relative !important;
  width: 100%;
  opacity: 0.94;
  transition: opacity var(--transition-400ms) ease, background-color var(--transition-400ms) ease;
  border-radius: var(--block-rounded);
  /* padding-top: 100%; */
  height: 100%;
  display: inline-block;
  word-break: break-word;
  /* overflow: hidden; */
}

@media only screen and (max-width: 576px) {
  .smaller-text {
    font-size: 11px;
  }

  .smaller-h2 {
    font-size: 20px;
  }
}

.animate-inputs {
    animation: appear-left 500ms ease-out forwards;
}

.animate-inputs-right {
    animation: appear-right 500ms ease-out forwards;
}

@keyframes appear-left {
    0% {
        transform: translateX(-32px);
        opacity: 0;
    }

    100% {
        transform: 0;
        opacity: 1;
    }
}

@keyframes appear-right {
    0% {
        transform: translateX(64px);
        opacity: 0;
    }

    100% {
        transform: 0;
        opacity: 1;
    }
}

.block:hover {
  opacity: 1;
  background-color: #fff;
}

.icon-new {
  height: 1.35em;
  margin-bottom: 3px;
  animation: fade-in-out 1.5s infinite linear;
}

@keyframes fade-in-out {
  50% {
    opacity: 0.1;
  }
}

.fade-in {
    animation: fade var(--transition-400ms) forwards;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

input,
select,
textarea {
  position: relative;
}

.icon-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  opacity: 0.05;
  transition: opacity var(--transition-400ms) ease;
}

.icon-container {
  opacity: 0.16;
}

h1,
h2,
h3,
h4,
h5,
h6,
lead {
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  position: relative;
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.notif {
  border-radius: 6px;
  padding: 0.19em;
  width: auto;
  color: #fff;
  background-color: var(--warning);
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  text-align: center;
  box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.3);
  z-index: 32;
  cursor: pointer;
}

.crowdsurance-notif {
  color: #fff !important;
  background-color: var(--crowdsurance-notification);
}

.notif-smaller {
  border-radius: 6px;
  font-size: 10px;
  padding: 0.19em;
  width: auto;
  color: #fff;
  background-color: var(--warning);
  position: absolute;
  right: 0px;
  top: 0px;
  width: 20px;
  height: 20px;
  text-align: center;
  box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.3);
  z-index: 32;
  cursor: pointer;
}

.crowdsurance .notif-smaller {
  background-color: var(--crowdsurance-notification) !important;
  color: #fff !important;
}

span {
  font-weight: bolder;
  margin: auto;
}

.row {
  padding-bottom: 0 !important;
}

.icon {
  position: relative;
  font-size: 150px;
  opacity: 3%;
  top: calc(50% - 75px);
}

.icon-img {
  width: 75%;
}

.small-icon {
  width: 1em;
  height: 1em;
  transform: translateY(-0.15em);
}

.block-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  transform: scale(50%);
}

.block-backdrop:hover {
  filter: saturate(100%);
}
.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
}
h2 {
  font-size: 24px;
  font-weight: 400;
}
.bottom-text {
  position: absolute;
  bottom: 8px;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.small-italic {
    font-size: 12px;
    font-style:italic;
}
.crowdsurance-title {
  font-family: "Intro Regular", Arial, sans-serif;
  font-weight: 400 !important;
  color: var(--crowdsurance-header) !important;
}
.crowdsurance {
  font-family: "PT Sans", Arial, sans-serif;
  color: var(--crowdsurance-header) !important;
}
.backdrop-crowdsurance {
  background-color: var(--crowdsurance-background);
}
.balance {
  color: #fff !important;
}
.crowdsurance .btn-primary {
  color: #fff !important;
}
.crowdsurance .back {
  color: var(--crowdsurance-header) !important;
}
.crowdsurance a:not(.btn) {
  color: #5583D4 !important;
}
.balloon-received {
    border-radius: 14px;
    float: left;
    overflow: hidden;
}
.balloon-sent {
    border-radius: 14px;
    background-color: #8ef4ff !important;
    float: right;
    overflow: hidden;
}
.bg-blue {
  background-color: var(--dark-blue-hover);
  color: #fff !important;
  transition: background-color var(--transition-400ms) ease;
}
.crowdsurance h3, .crowdsurance h4 {
  font-family: "Intro Regular", sans-serif;
  font-size: 28px;
}
.crowdsurance h4 {
  margin-top: 8px;
}
.crowdsurance .btn-danger {
  background-color: #EC6D9B !important;
  border-color: #EC6D9B !important;
  font-family: "PT Sans", sans-serif;
  color: #f5fcfc !important;
}
.crowdsurance .btn-warning, .crowdsurance .bg-warning {
  background-color: #FBC037 !important;
  border-color: #FBC037 !important;
  color: #004B5A !important;
  font-family: "PT Sans", sans-serif;
}
.crowdsurance .btn-info,
.crowdsurance .btn-primary {
  background-color: #00a8ba !important;
  border-color: #00a8ba !important;
  font-family: "PT Sans", sans-serif;
  color: #f5fcfc;
}
.crowdsurance .bg-light {
  background-color: #fff !important;
  border: 1px solid #eee;
}
.crowdsurance .bg-warning {
  background-color: #FBC037 !important;
  border: 1px solid #FBC037 !important;
}
.crowdsurance .bg-info {
  background-color: #00a8ba !important;
  border: 1px solid #00a8ba !important;
  color: #f5fcfc !important;
}
.crowdsurance .balloon-sent {
  background-color: #00a8ba !important;
}
.crowdsurance .balloon-received {
  background-color: transparent !important;
  border: 1px solid #00a8ba !important;
  color: #00a8ba !important;
}
.crowdsurance table tr td,
.crowdsurance table tr,
.crowdsurance th {
    color: var(--crowdsurance-header) !important;
}
.current-brand > td {
    color: #002442 !important;
    font-weight: 800;
}
.current-brand-info > td {
    color: #002442 !important;
    background-color: #e8f4fa !important;
}
.inactive {
    background-color: #ffffff33 !important;
    opacity: 0.5;
}
.not-available {
    background-color:#ff001944 !important;
}
.current-brand {
    background-color: #fff !important;
}
.crowdsurance .not-available {
    background-color:#EC6D9B44 !important;
}
.gravatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 3px 5px 9px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
}
td.bg-light {
  border: 0 !important;
  border-bottom: 1px solid #eee !important;
}
/* Toasts */
.crowdsurance > div.Vue-Toastification__toast {
  font-family: "PT Sans", sans-serif;
  box-shadow: none !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--error {
  background-color: #EC6D9B !important;
  color: #f5fcfc !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--error > .Vue-Toastification__toast-body{
  color: #f5fcfc !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--success {
  background-color: #00a8ba !important;
  color: #f5fcfc !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--success > .Vue-Toastification__toast-body{
  color: #f5fcfc !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--warning {
  background-color: #fbc037 !important;
  color: var(--crowdsurance-header) !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--warning > .Vue-Toastification__toast-body{
  color: var(--crowdsurance-header) !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--info {
  background-color: #00a8ba !important;
  color: #f5fcfc !important;
}
.crowdsurance > div.Vue-Toastification__toast.Vue-Toastification__toast--info > .Vue-Toastification__toast-body{
  color: #f5fcfc !important;
}
</style>

<script type="text/javascript">
import $ from 'jquery';

export class Breadcrumb {
  constructor(url, routeName) {
    this.url = url;
    this.routeName = routeName;
  }
}

export const MessageType = {
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
  Error: 'danger'
}

export const getCustomerStorage = function() {
  return localStorage.getItem('customerStorage') === "localStorage" ? localStorage : sessionStorage;
}

export const isKnab = function() {
  let c = getCustomer();
  if(!c || !c.current_brand_id)
    return false;
  return c.current_brand_id === 4;
}

export const updateAppearanceKnab = function() {
  $("h1, h2, h3, h4, h5, h6, p, span, div, a, td, th").addClass("crowdsurance");
  $(".title").removeClass("crowdsurance").addClass("crowdsurance-title");
  $("#backdropPlanet, #backdropRain").remove();
  $("body").removeClass("backdrop-zo").addClass("backdrop-crowdsurance");
}

export const setCustomer = function(customer) {
  const customerData = JSON.stringify(customer);
  getCustomerStorage().setItem('customer', customerData)
}
export const getCustomer = function() {
  const customerData = getCustomerStorage().getItem('customer')

  if(customerData) {
    return JSON.parse(customerData)
  } else {
    return null;
  }
}

export default {
  data() {
    return {
    }
  },
  components: {
  },
  
  mounted() {
    let updateDashboardRoutes = [
      '/login',
      '/forgot-password',
      '/reset-password',
      '/impersonate',
    ];

    setTimeout(() => {
      if(updateDashboardRoutes.indexOf(this.$router.currentRoute._rawValue.path) < 0) {
        this.$http.get('/dashboard')
            .then((response) => {
                // Deze functie verzorgt de opmaak van het hamburgermenu met een bolletje en het aantal notificiaties voor de gebruiker
                this.$hasActions(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
      }
    }, 200);
    
    const backdropRain = document.getElementById("backdropRain");
    window.onscroll = function() {
      if(backdropRain)
        backdropRain.style = `background-position: 0px ${-window.scrollY / 2}px` ;
    }
  },
}
</script>
