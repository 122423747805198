<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs"/>

            <h4 class="display-4 text-white fw-normal title">Nieuws</h4>
            <hr>

            <div class="all-news-articles w-100">
                <div v-for="article in articles" :key="article.id" @click="() => $router.push(`/news/${article.id}`)">
                    <div class="article position-relative" title="Lees meer...">
                        <h3 class="text-white fw-bold">{{ article.title }}</h3>
                        <p class="text-white small d-inline-block mb-2">
                            <mark>{{ $formatDate(article.publish_at) }}</mark>
                        </p>
                        <div class="rounded-1 w-100 mb-3 position-relative">
                            <img v-if="article.image_url" :src="article.image_url" class="w-100 max-height-240 rounded-1">
                            <!-- <div class="vignette"></div> -->
                        </div>

                        <!-- Inhoud met maximale hoogte -->
                        <div class="max-height-article position-relative">
                            <div class="text-white paragraph" v-html="article.article_intro"></div>
                            <div class="text-white paragraph" v-html="article.article_continuation"></div>
                        </div>
                        <hr class="w-100 mb-0"/>
                    </div>
                </div>
            </div>

            <div class="mt-2">
                <Pagination :pagination="articlesPagination" :currentPaginationPage="currentPaginationPage"
                    @updatePagination="(url, label) => paginate(url, label)" v-if="articles.length > 0" />

                <p class="bg-info text-dark p-3 rounded-2 small" v-else>
                    <i class="fa fa-info-circle me-1"></i>Er zijn momenteel nog geen nieuwsartikelen geplaatst.
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>
hr {
    border-color: rgba(255, 255, 255, 0.6);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

a {
    text-decoration: none !important;
}

mark {
    background-color: var(--dark-blue);
    color: #fff;
    border-radius: 4px;
    padding: 5px;
    padding-right: 8px;
}

.article {
    border-radius: 3px;
    overflow: hidden;
    transition: background-color var(--transition-400ms) ease, margin var(--transition-400ms) ease, padding var(--transition-400ms) ease;
    padding: 16px;
    padding-left: 0;
    padding-right: 0;
}

.article:hover {
    padding: 16px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    margin-left: -16px;
    margin-right: -16px;
}

.max-height-article {
    max-height: 120px;
    overflow: hidden;
}

.img {
    width: 100%;
    position: relative;
    z-index: 32;
}

.bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}

.max-height-240 {
    max-height: 240px;
    object-fit: cover;
}

.vignette {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.7) inset;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)); */
}

.fw-normal {
    font-weight: 400 !important;
}
</style>

<script type="text/javascript">
import { Breadcrumb, updateAppearanceKnab, isKnab } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import $ from "jquery";
import Pagination from '@/components/PaginationComponent.vue';

export default {
    name: 'news-component',
    components: {
        Breadcrumbs,
        Pagination,
    },
    data() {
        return {
            articles: [],
            breadcrumbs: [new Breadcrumb("/news", "Nieuws")],
            articlesPagination: undefined,
            currentPaginationPage: '1',
        }
    },
    methods: {
        async getArticles() {
            try {
                await this.$http.get('news_articles')
                    .then((response) => {
                        this.articles = response.data.data;

                        this.articlesPagination = response.data.links;
                        this.articlesPagination[0].label = "<";
                        this.articlesPagination[this.articlesPagination.length - 1].label = ">";
                        this.currentPaginationPage = response.data.current_page.toString();

                        setTimeout(() => {
                            if(isKnab()) {
                                $(".text-white").removeClass("text-white");
                                updateAppearanceKnab();
                            }
                            $(".all-news-articles").show(400);
                        }, 150);
                    });
            } catch(error) {
                console.error(error);
            }
        },
        paginate(url, label) {
            if(!url)
                return;

            if(!this.$canUsePagination(url, label))
                return;

            this.$http.get(url)
                .then((response) => {
                    let data = response.data;
                    this.articles = data.data;
                    this.articlesPagination = data.links;

                    this.articlesPagination[0].label = "<";
                    this.articlesPagination[this.articlesPagination.length - 1].label = ">";
                    
                    this.currentPaginationPage = data.current_page.toString();
                });
        },
        imgurl(filename) {
            let images = require.context('../assets/', false, /\.jpg$/);
            return images(`./${filename}`);
        },
    },
    mounted() {
        if(isKnab()) {
            updateAppearanceKnab();
        }
        $(".all-news-articles").hide();
        this.getArticles();
    }
}
</script>