<template>
  <div :class="[isOpen ? 'behind-black' : 'behind-black hide-black']" @click="isOpen = false"></div>

  <div class="top-bar">
    <div class="white-bg"></div>
    <div class="position-relative">
      <!-- <i class="fa fa-bars opacity-0" v-if="!$authenticated"></i> -->
      <div class="filler"></div>
      <i :class="['fa fa-bars hamburger-authentication', isOpen ? 'hide-icon' : '']" id="openHamburger" @click="() => isOpen = !isOpen"></i>
      <i :class="['fa fa-times larger hamburger-authentication', !isOpen ? 'hide-icon' : '']" id="openHamburger"
        @click="() => isOpen = !isOpen"></i>

      <!-- Bolletje dat toont of er acties zijn -->
      <div class="has-actions"></div>
      <img src="@/assets/logo-zelfstandig-ondernemers.svg" class="position-absolute logo"
        alt="Logo van Zelfstandig Ondernemers">
      <img src="@/assets/brand-logo.svg" class="logo-brand"
        alt="Logo van Knab">
    </div>
  </div>

<div :class="['hamburger-menu hamburger-authentication', isOpen ? '' : 'hidden max-h']">
    <router-link to="/" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-home fsi"></i>&nbsp;Home
      </div>
    </router-link>

    <template v-if="!isCrowdsurance()">
      <router-link to="/saldo" @click="close">
        <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/saldo') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
          <i class="fa fa-wallet fsi" :class="['small-icon me-3', isCurrentRoute('/saldo') ? 'invert' : '']"></i>&nbsp;Mijn spaarpot
        </div>
      </router-link>
    </template>
    <template v-else>
      <router-link to="/saldo" @click="close">
        <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/saldo') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
          <i class="fa fa-wallet fsi" :class="['small-icon me-3', isCurrentRoute('/saldo') ? 'invert' : '']"></i>&nbsp;Mijn buffer
        </div>
      </router-link>
    </template>

    <router-link to="/ao-percentage" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/ao-percentage') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-percent fsi"></i>&nbsp;Statistieken
      </div>
    </router-link>

    <router-link to="/gifts" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/gifts') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-gift fsi"></i>&nbsp;Schenkingen
      </div>
    </router-link>

    <template v-if="!isCrowdsurance()">
      <router-link to="/products" @click="close">
        <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/products') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
          <img src="@/assets/quad_products.svg" alt="Mijn producten icoon" :class="['small-icon me-3', isCurrentRoute('/products') ? 'invert' : '']">&nbsp;Mijn producten
        </div>
      </router-link>
    </template>
    <template v-else>
      <router-link to="/products-crowdsurance" @click="close">
        <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/products-crowdsurance') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
          <img src="@/assets/quad_products.svg" alt="Mijn producten icoon" :class="['small-icon me-3', isCurrentRoute('/products') ? 'invert' : '']">&nbsp;Crowdsurance
        </div>
      </router-link>
    </template>

    <router-link to="/invoices" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative',isCurrentRoute('/invoices') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-list-alt fsi"></i>&nbsp;Administratieoverzicht
        <div class="notification open-administration"></div>
      </div>
    </router-link>

    <router-link to="/disability" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/disability') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <img src="@/assets/brokenleg-icon.svg" alt="Mijn ziekmeldingen icoon" :class="['small-icon me-3', isCurrentRoute('/disability') ? 'invert' : '']">&nbsp;Mijn ziekmeldingen
        <div class="notification open-disabilities"></div>
      </div>
    </router-link>

    <router-link to="/news" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/news') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-book fsi"></i>&nbsp;Nieuws
        <div class="notification new-news"></div>
      </div>
    </router-link>

    <router-link to="/messages" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/messages') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-envelope fsi"></i>&nbsp;Berichten
        <div class="notification new-messages"></div>
      </div>
    </router-link>

    <router-link to="/personalia" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/personalia') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-user fsi"></i>&nbsp;NAW gegevens
      </div>
    </router-link>

    <router-link to="/account" @click="close">
      <div :class="['p-md-3 p-25 w-100 position-relative', isCurrentRoute('/account') ? 'bg-blue text-light' : 'bg-light text-dark-blue']">
        <i class="fa fa-key fsi"></i>&nbsp;Account
      </div>
    </router-link>

    <div class="p-md-3 p-25 bg-light w-100 text-danger sign-out" @click="logout">
      <i class="fa fa-door-open fsi"></i>&nbsp;Uitloggen
    </div>
  </div>
</template>

<style scoped>
.top-bar {
  backdrop-filter: blur(6px);
  padding: 1em;
  position: relative;
  display: block;
  /* transform: translateY(calc(-80px + 1em)); */
  /* box-shadow: 4px 7px 12px rgba(0, 0, 0, 0.1); */
}

.text-dark-blue {
  color: var(--dark-blue) !important;
}

.space {
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -500;
}

.invert {
  filter: invert() brightness(500%);
}

.filler {
  width: 0;
  display: inline-block;
  height: 1em;
}

.p-25 {
  padding: calc(100vw / 32);
}

.white-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
}

.fa {
  transition: opacity var(--transition-400ms) ease;
}

* {
  box-sizing: border-box;
}

.has-actions {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  font-size: 24px;
  left: calc(1em - 8px);
  top: -2px;
  background-color: var(--warning);
  display: none;
}

.crowdsurance .has-actions {
  background-color: var(--crowdsurance-notification) !important;
}

.fa-bars {
  transform: translateY(2px);
}

.notification {
  width: 24px;
  height: 24px;
  text-align: center;
  top: calc(50% - 12px);
  position: absolute;
  right: 12px;
  border-radius: 4px;
  background-color: var(--warning);
  color: #fff;
  font-weight: bold;
}

.crowdsurance .notification {
  background-color: var(--crowdsurance-notification) !important;
  color: #fff !important;
}

.sign-out {
  cursor: pointer;
}

.fsi {
  transform: translateY(1px);
  margin-right: 1em;
}

.fa-bars {
  font-size: 24px;
}

.hide-icon {
  pointer-events: none;
  opacity: 0;
}

.logo {
  height: 100%;
  right: 8px;
  position: absolute;
  max-width:33%;
}

.logo-brand {
  height: 90%;
  top: 5%;
  left: 40px;
  position: absolute;
  max-width:33%;
}

.larger {
  font-size: 26px;
  position: absolute;
  left: 0;
}

.fa {
  cursor: pointer;
}

.hamburger-menu {
  width: 95vw;
  background-color: #F8F9FA;
  transform: 0;
  transition: transform var(--transition-400ms) ease;
  max-width: 400px;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 59px);
  overflow-y: auto;
}

.hidden {
  height: calc(100vh - 59px);
  transform: translateX(-100%);
  pointer-events: none;
  z-index: 50;
  overflow-y: auto;
}

.behind-black {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity var(--transition-400ms) ease;
}

.hide-black {
  pointer-events: none;
  opacity: 0;
}

.bg-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  transition: background-color var(--transition-200ms) ease;
}

a {
  color: var(--dark-blue);
  text-decoration: none !important;
}

.bg-light:hover {
  background-color: #e2e2e2 !important;
}
</style>

<script type="text/javascript">
import { getCustomerStorage, getCustomer, isKnab } from '@/App.vue';
import { useRoute } from 'vue-router';
import $ from 'jquery';

export default {
  data() {
    return {
      loggedIn: false,
      userName: '',
      publicPath: process.env.BASE_URL,
      isOpen: false,
      dashboardData: {},
      current_brand_id: 0,
      brand_logo: "brand-logo.svg",
      dynamic: undefined,
    };
  },
  methods: {
    isCrowdsurance() {
        return isKnab();
    },
    async logout() {
      let query = {};
      if(isKnab()) {
        query = {
          'brand': 'knab',
        }
      }

      localStorage.clear();
      sessionStorage.clear();
      
      this.emitter.emit('login', false)
      this.close();

      await this.$router.push({ path: '/login',
          query: query,
      });
    },
    isCurrentRoute(path) {
      const route = useRoute()
      const currentRoutePath = route.path
      
      if(path == '/') {
        return currentRoutePath == '/'
      }
      return currentRoutePath.indexOf(path) === 0;
    },
    close() {
      this.isOpen = false;
    },
    getImgUrl(image) {
      var images = require.context('../assets/', false, /\.svg$/)
      return images('./' + image)
    }
  },
  async mounted() {
    $(".logo-brand").hide();

    this.emitter.on('login', loggedIn => {
      this.loggedIn = loggedIn;

      this.userName = ''
      this.$authenticated = loggedIn;

      if (loggedIn) {
        try {
          const customer = getCustomer()
          this.userName = (customer.firstname ? customer.firstname + ' ' : '') + (customer.middlename ? customer.middlename + ' ' : '') + customer.lastname;
          this.current_brand_id = customer.current_brand_id;
          if(isKnab()) {
            $(".logo-brand").show(400);
          }
        } catch (e) {
          console.error(e);
        }
      }
    });

    if(this.$route.query.brand === 'knab')
      $(".logo-brand").show(400);

    if (getCustomerStorage().getItem('token') !== null) {
      this.emitter.emit('login', true)
    }
  },
};
</script>