<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="text-white display-4 fw-normal title">Mijn producten</h1>

            <!-- 
            <div class="row" id="changeRow">
                <div class="col mt-1">
                    <a class="btn btn-primary btn-bg-light-blue float-start"><i
                            class="fa fa-save"></i> Wijzigen per {{ $formatDate(dynamic.change_at) }}</a>
                </div>
            </div> 
        
            <h6 class="text-white h6-product mt-3">Kies je producten</h6>
            -->

            <div class="row mb-2">
                <div class="col mt-2" v-if="dynamic.brand && dynamic.brand.dynamic_participants == 0">
                    <router-link to="products/to-zo" class="btn btn-primary btn-bg-light-blue float-end"><i class="fa fa-info-circle me-1"></i> Overstappen naar de producten van Zelfstandig Ondernemers</router-link>
                </div>
                
                <div class="col mt-2" v-if="dynamic.brand && dynamic.brand.dynamic_participants == 1">
                    <router-link to="products/change" class="btn btn-primary btn-bg-light-blue float-end">
                        <img src="@/assets/products_white_blue.svg" class="small-icon me-1 ">
                        <template v-if="showAovSubgroup && showPaymentSubgroup">
                            Je producten wijzigen vanaf {{ dynamic.change_at ? $formatDate(dynamic.change_at) : '' }}
                        </template>
                        <template v-else>
                            Kies producten vanaf {{ dynamic.change_at ? $formatDate(dynamic.change_at) : '' }}
                        </template>
                    </router-link>
                </div>
            </div>

            <div class="tab-line d-flex position-relative">
                <div v-for="(change, index) in changes" :key="index" :class="['btn btn-light tab float-start small', +change == +showChange ? '' : 'opacity-50']" @click="toggleChange(change)">
                    {{ +change == +now ? 'Huidige samenstelling' : $formatDate(change) }}
                </div>
            </div>

            <div id="warning" class="position-relative" v-if="+showChange != +now">
                <div class="bg-info text-dark small p-3 rounded-2 my-3 mb-2">
                    <i class="fa fa-exclamation me-1"></i>Let op! Deze gegevens gelden pas vanaf {{ $formatDate(showChange) }}.
                </div>
            </div>

            <template v-if="showAovSubgroup && showPaymentSubgroup">
                <div class="row">
                    <div class="col mt-2">
                        <div class="bg-light text-dark p-2 px-3 rounded-2 float-start position-relative fade-in" :key="showPaymentSubgroup.subgroup_payment_monthy">Maandelijkse inleg: {{ $numberFormatEuro(showPaymentSubgroup.subgroup_payment_monthy, 0) }}</div>
                    </div>
                </div>

                <div class="row row-cols-1 row-cols-sm-2 p-1 position-relative" id="allProducts">
                    <div class="col p-2">
                        <div class="block block-product p-3 h-100 position-relative">
                            <h3 class="text-center h3-product my-2 mb-3">AOV</h3>
                            <div class="text-center mb-2">
                                <img class="icon-products" src="@/assets/icon-bandage.svg" alt="Pleister">
                            </div>
                            <h4 class="text-center m-2 mt-3 h4-product aov fade-in" :key="showAovSubgroup.expected_monthly_costs">{{ $numberFormatEuro(showAovSubgroup.expected_monthly_costs, 0) }} p/m
                            </h4>
                            <p class="text-center small font-italic m-0">Inclusief vangnetkosten</p>
                            <p class="text-center small font-italic m-0">Inkomen bij ziekte ({{ showAovSubgroup.payout_max_months/12 }} jaar):</p>
                            <template v-if="!dynamic.disabled_now">
                                <h5 class="text-center m-2 h5-product aov fade-in" :key="showAovSubgroup.subgroup_payout_monthly">{{ $numberFormatEuro(showAovSubgroup.subgroup_payout_monthly, 0) }}</h5>
                                <p class="text-center small m-0">netto per maand</p>
                            </template>
                            <template v-if="dynamic.disabled_now">
                                <div class="text-center my-1 aov">
                                    <p class="fw-bold m-0">{{ $numberFormatEuro(showAovSubgroup.subgroup_payout_monthly, 0) }}</p>
                                    <p class="text-center small m-0">netto per maand</p>
                                </div>

                                <div class="text-center my-1 aov">
                                    <h5 class="h5-product m-0">{{ $numberFormatEuro(showAovSubgroup.subgroup_payout_monthly - (showCovSubgroup?.monthly_costs ?? 0) - show_retirement_after_payment_amount - show_savings_during_payment_amount - (showAovSubgroup?.monthly_costs ?? 0), 0) }}</h5>
                                    <p class="text-center smaller m-0">na aftrek kosten overige producten</p>
                                </div>
                            </template>
                            <div class="icon-container">
                                <i class="fa fa-plus icon"></i>
                            </div>
                        </div>
                    </div>

                    <div class="col p-2">
                        <div class="block block-product p-3 h-100 position-relative">
                            <h3 class="text-center h3-product my-2 mb-3">Ongevallenverzekering</h3>
                            <div class="text-center mb-2">
                                <img class="icon-products" src="../assets/icon-brokenleg.svg" alt="Pleister">
                            </div>
                            <h4 class="text-center m-2 mt-3 h4-product cov fade-in" :key="showCovSubgroup?.monthly_costs">{{ $numberFormatEuro(showCovSubgroup?.monthly_costs ?? 0, 0) }} p/m</h4>
                            <p class="text-center small font-italic m-0">Geen kosten</p>
                            <p class="text-center small font-italic m-0">Uitkering bij ongeval</p>
                            <p class="text-center small font-italic m-0">(50% bij overlijden):</p>
                            <h5 class="text-center my-1 h5-product cov fade-in" :key="showCovSubgroup?.payout_once">{{ $numberFormatEuro(showCovSubgroup?.payout_once ?? 0, 0) }}</h5>
                            <p class="text-center small m-0">In één keer</p>
                            <div class="icon-container">
                                <i class="fa fa-plus icon"></i>
                            </div>
                        </div>
                    </div>

                    <template v-if="!dynamic.disabled_now">
                        <!-- inleg en sparen zonder ziekte tonen -->
                        <div class="col p-2">
                            <div class="block block-product p-3 h-100 position-relative">
                                <h3 class="text-center h3-product my-2 mb-3">Pensioen</h3>
                                <div class="text-center mb-2">
                                    <img class="icon-products" src="../assets/icon-oldman.svg" alt="Pleister">
                                </div>
                                <h4 class="text-center m-0 h4-product pension fade-in" :key="show_retirement_after_directdebit_amount">{{ $numberFormatEuro(show_retirement_after_directdebit_amount, 0) }} p/m</h4>
                                <p class="text-center small font-italic m-0">Geen kosten</p>
                                <p class="text-center small font-italic m-0">In je pensioenpot</p>
                                <p v-if="customer.iban_retirement === null && show_retirement_after_directdebit_amount > 0" class="text-center smaller m-0">De pensioenopbouw start zodra uw pensioenrekening is geactiveerd.
                                </p>
                                <div v-if="customer.iban_retirement === null" class="col text-center my-2">
                                    <router-link to="/retirement-account" class="btn btn-light small text-nowrap"><i class="fa fa-link me-1"></i> IBAN pensioenspaarrekening aanvragen</router-link>
                                </div>
                                <div class="icon-container">
                                    <i class="fa fa-plus icon"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col p-2">
                            <div class="block block-product p-3 h-100 position-relative">
                                <h3 class="text-center h3-product my-2 mb-3">Sparen</h3>
                                <div class="text-center mb-2">
                                    <img class="icon-products" src="../assets/icon-piggy.svg" alt="Pleister">
                                </div>
                                <h4 class="text-center m-0 h4-product savings fade-in" :key="show_savings_after_directdebit_amount">{{ $numberFormatEuro(show_savings_after_directdebit_amount, 0) }} p/m</h4>
                                <p class="text-center small font-italic m-0">Geen kosten</p>
                                <p class="text-center small font-italic m-0">In je spaarpot als buffer:</p>
                                <h5 class="text-center m-0 h5-product savings fade-in" :key="show_savings_after_directdebit_amount">{{ $numberFormatEuro(show_savings_after_directdebit_amount, 0) }}</h5>
                                <p class="text-center small m-0">per maand</p>
                                <div class="icon-container">
                                    <i class="fa fa-plus icon"></i>
                                </div>
                            </div>
                        </div>
                    </template>
                    
                    <template v-if="dynamic.disabled_now">
                        <!-- inleg en sparen met ziekte tonen -->

                        <div class="col p-2">
                            <div class="block block-product p-3 h-100 position-relative">
                                <h3 class="text-center h3-product my-2 mb-3">Pensioen van schenking</h3>
                                <div class="text-center mb-2">
                                    <img class="icon-products" src="../assets/icon-oldman.svg" alt="Pleister">
                                </div>
                                <h4 class="text-center m-0 h4-product pension">{{ $numberFormatEuro(show_retirement_after_payment_amount, 0) }} p/m</h4>
                                <p class="text-center small font-italic m-0">Geen kosten</p>
                                <p class="text-center small font-italic m-0">In je pensioenpot</p>
                                <p v-if="customer.iban_retirement === null && show_retirement_after_payment_amount > 0" class="text-center smaller m-0">De pensioenopbouw start zodra uw pensioenrekening is geactiveerd.
                                </p>
                                <div v-if="customer.iban_retirement === null" class="col text-center my-2">
                                    <router-link to="/retirement-account" class="btn btn-light"><i class="fa fa-link me-1"></i> IBAN pensioenspaarrekening aanvragen</router-link>
                                </div>
                                <div class="icon-container">
                                    <i class="fa fa-plus icon"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col p-2">
                            <div class="block block-product p-3 h-100 position-relative">
                                <h3 class="text-center h3-product my-2 mb-3">Sparen van schenking</h3>
                                <div class="text-center mb-2">
                                    <img class="icon-products" src="../assets/icon-piggy.svg" alt="Pleister">
                                </div>
                                <h4 class="text-center m-0 h4-product savings">{{ $numberFormatEuro(show_savings_during_payment_amount, 0) }} p/m</h4>
                                <p class="text-center small font-italic m-0">Geen kosten</p>
                                <p class="text-center small font-italic m-0">In je spaarpot als buffer:</p>
                                <h5 class="text-center m-0 h5-product savings">{{ $numberFormatEuro(show_savings_during_payment_amount, 0) }}</h5>
                                <p class="text-center small m-0">per maand</p>
                                <div class="icon-container">
                                    <i class="fa fa-plus icon"></i>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else> 
                <table v-if="active_participants.length" class="table position-relative text-white">
                    <thead>
                        <tr>
                            <th class="text-white">Product</th>
                            <th class="text-white">Start</th>
                            <th class="text-white">Eind</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="participant in active_participants" :key="participant.id" class="position-relative">
                            <td class="text-white">{{ participant.subgroup?.name }}</td>

                            <td class="text-white">{{ $formatDate(participant.start_at) }}</td>
                            <td class="text-white">{{ participant.end_at ? $formatDate(participant.end_at) : '' }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </div>
    </div>
</template>

<style scoped>
.bg-green {
    background-color: var(--light-green);
}

.smaller-icon {
    font-size: 20px !important;
    transform: translateY(-10px) !important;
}

.white-icon {
    filter:();
}

.inleg {
    background-color: #fff;
    text-align: center;
    font-weight: 800;
    transition: color var(--transition-400ms) ease, box-shadow var(--transition-200ms) ease, background-color var(--transition-400ms) ease;
    cursor: pointer;
}


table tr td,
table tr,
th {
    background-color: transparent !important;
}

.inleg:hover {
    box-shadow: 2px 3px 20px rgba(255, 255, 255, 0.3);
}

.inleg-chosen {
    background-color: #00B1C5;
    color: var(--dark-blue)
}


.add {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 24px;
}

.subtract {
    position: absolute;
    left: 12px;
    top: 50%;
    bottom: 50%;
    font-size: 40px;
    font-weight: 100;
    transform: translateY(-20px);
    cursor: pointer;
    transition: opacity var(--transition-200ms) ease;
}

.add:hover {
    opacity: 0.7;
}

.subtract:hover {
    opacity: 0.7;
}

.icon-products {
    width: 20%;
}

@media screen and (max-width: 768px) {
    .icon-products {
        width: 25%;
    }
}

.h3-product {
    font-weight: 800;
    font-size: 24px;
}

.h4-product {
    font-weight: bold;
    font-size: 24px;
    color: #00B1C5;
}

.h5-product {
    font-weight: bold;
    font-size: 24px;
}

.small {
    font-size: 14px;
}

.smaller {
    font-size: 11px;
}

.h6-product {
    font-weight: 800;
    font-size: 15px;
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

.tab-line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.tab {
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    border: 0 !important;
    transition: opacity var(--transition-200ms) ease-in-out;
}

.tab:hover {
    background-color: #fff;
}

.invert {
    filter: invert() brightness(500%);
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>

<script type="text/javascript">
import { Breadcrumb } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { getCustomerStorage } from '@/App.vue';

export default {
    name: 'product-page',
    components: {
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/products', 'Mijn producten')],
            customer: {},
            current_brand_id: 0,
            
            dynamic: {},
            active_participants: [],
            now: new Date(new Date().setHours(0,0,0,0)),
            selected: new Date(new Date().setHours(0,0,0,0)),
            changes: [],
            showChange: null,
            
            showPaymentParticipant: null,
            showPaymentSubgroup: null,
            
            showAovParticipant: null,
            showAovSubgroup: null,
            
            showCovParticipant: null,
            showCovSubgroup: null,

            show_retirement_after_payment_amount: 0,
            show_retirement_after_directdebit_amount: 0,

            show_savings_after_directdebit_amount: 0,
            show_savings_during_payment_amount: 0,

        }
    },
    methods: {
        toggleChange(change) {
            this.showChange = change;
            
            this.showPaymentParticipant = null;
            this.showPaymentSubgroup = null;
            
            this.showAovParticipant = null;
            this.showAovSubgroup = null;
            
            this.showCovParticipant = null;
            this.showCovSubgroup = null;

            this.show_retirement_after_directdebit_amount = 0;
            this.show_retirement_after_payment_amount = 0;

            this.show_savings_after_directdebit_amount = 0;
            this.show_savings_during_payment_amount = 0;

            this.active_participants = [];

            this.dynamic.participants.forEach(function(participant) {
                const start_at = new Date(participant.start_at),
                    end_at = participant.end_at ? new Date(participant.end_at) : null;

                if(start_at <= change && (!end_at || end_at >= change)) {
                    this.active_participants.push(participant)

                    this.dynamic.payment_subgroups.forEach(function(subgroup) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showPaymentParticipant = participant;
                            this.showPaymentSubgroup = subgroup;

                            if(participant.active_payout_requests.length) {
                                participant.active_payout_requests.forEach(function(payout_request) {
                                    if(payout_request.type == 'payout_after_directdebit_sure' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_directdebit_amount = payout_request.amount;
                                    } else if(payout_request.type == 'payout_after_payment' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_payment_amount = payout_request.amount;
                                    } else if(payout_request.type == 'savings_during_payment' && payout_request.user_iban == null) {
                                        this.show_savings_during_payment_amount = payout_request.amount;
                                    }

                                }.bind(this));
                            }
                        }
                    }.bind(this));

                    this.dynamic.aov_subgroups.forEach(function(subgroup) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showAovParticipant = participant;
                            this.showAovSubgroup = subgroup;
                        }
                    }.bind(this));

                    this.dynamic.cov_subgroups.forEach(function(subgroup) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showCovParticipant = participant;
                            this.showCovSubgroup = subgroup;
                        }
                    }.bind(this));
                }
            }.bind(this));

            this.show_savings_after_directdebit_amount = (this.showPaymentSubgroup?.subgroup_payment_monthy ?? 0) - (this.showAovSubgroup?.expected_monthly_costs ?? 0) - (this.showCovSubgroup?.monthly_costs ?? 0) - this.show_retirement_after_directdebit_amount;
        },
    },
    async mounted() {
        const response = await this.$http.get('/participants/dynamic');
        this.dynamic = response.data;

        let customer = getCustomerStorage().getItem("customer");
        if(!customer) {
            console.log("Customer not found");
            return;
        }
        this.customer = JSON.parse(customer);

        
        this.now = new Date(new Date().setHours(0,0,0,0));

        // STAP 1 breakpoinst bepalen
        this.changes = [this.now];
        this.dynamic.participants.forEach(function(participant) {
            const start_at = new Date(participant.start_at),
                end_at = participant.end_at ? new Date(participant.end_at) : null;

            // opv start, in toekomst, of niet lege end
            if(start_at > this.now) {
                let change = new Date(start_at);
                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }
            if(end_at) {
                let change = new Date(end_at);
                change.setDate(change.getDate() + 1);

                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }

        }.bind(this));

        

        // STAP 2 huidig bepalen
        this.toggleChange(this.now);

        // STAP 3 als er nu geen product actief is (net nieuwe deelnemer bijv.) dan de deelnames per eerst volgende wijzigingen actief tonen
        if((!this.showAovSubgroup || !this.showPaymentSubgroup) && this.changes.map(Number).indexOf(+new Date(this.dynamic.change_at)) !== -1) {
            this.toggleChange(new Date(this.dynamic.change_at));
        }
    }
}
</script>
