<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs"/>
            <h1 class="text-white display-4 fw-normal title">Pensioenspaarrekening aanvragen</h1>
            <p class="w-100 bg-light text-dark p-3 rounded-2 small info"><i class="fa fa-info-circle me-1"></i> Het is mogelijk om een gratis pensioenspaarrekening te koppelen. Om pensioen op te bouwen heb je een pensioenrekening bij <a href="https://new.brandnewday.nl/welkom-ondernemer/">Brand New Day</a> nodig.</p>
            <p class="w-100 bg-info text-dark p-3 rounded-2 small info"><i class="fa fa-info-circle me-1"></i> Zodra je pensioenspaarrekening is gekoppeld aan je ondernemersvangnet nemen wij contact met je op.</p>
            <a href="https://new.brandnewday.nl/welkom-ondernemer/" class="btn btn-light" target="_blank"><i class="fa fa-link"></i> Pensioenspaarrekening aanvragen</a>
        </div>
    </div>
</template>

<style scoped>
hr {
    border-color: rgba(255, 255, 255, 0.2);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}
</style>

<script type="text/javascript">
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { Breadcrumb } from '@/App.vue';
import $ from 'jquery';

export default {
    name: "retirement-page",
    components: {
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb("/retirement-account", "Pensioenspaarrekening koppelen")],
        }
    },
    mounted() {
        $(".info").hide();
        $(".info").show('slow');
    }
}
</script>