<template>
        <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="text-white display-4 fw-normal title">Pagina niet gevonden</h1>
            <hr/>
            <p class="text-white my-4">De pagina die je probeert te bezoeken bestaat niet.</p>
            <h4 class="text-white fw-bold my-3">Wat je zou kunnen doen:</h4>
            <ul class="text-white position-relative mb-3">
                <li class="my-3">Controleer of je de juiste pagina probeert te bezoeken</li>
                <li class="my-3">Controleer of de URL in je zoekbalk klopt</li>
            </ul>
            <hr class="mb-4"/>
            <router-link to="/" class="btn btn-primary">
                <i class="fa fa-home me-1"></i>
                Terug naar Home
            </router-link>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { Breadcrumb, isKnab, updateAppearanceKnab } from '@/App.vue';

export default {
    name: 'error-404',
    components: {
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/404', 'Pagina niet gevonden')],
        }
    },
    mounted() {
        if(isKnab()) {
            updateAppearanceKnab();
            $(".text-white").removeClass("text-white");
        }
    }
}

</script>

<style scoped>
hr {
    border-color: rgba(255, 255, 255, 0.2);
}
</style>