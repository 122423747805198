<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs"></Breadcrumbs>
            <h1 class="text-white display-4 fw-normal title">Account</h1>
            <hr/>

            <div class="fade-all position-relative">
                <h4 class="text-white fw-bold">Wachtwoord wijzigen</h4>

                <!-- Huidig wachtwoord -->
                <form id="passwordForm" @submit.prevent="submit">
                    <div class="row row-cols-1 row-cols-md-2 position-relative">
                        <div class="col col">
                            <label for="currentPassword" class="text-white fw-bold mt-2">Huidig wachtwoord</label>
                        </div>
                        <div class="col">
                            <input type="password" id="currentPassword" class="form-control" placeholder="Huidig wachtwoord" :value="currentPassword" @input="(event) => currentPassword = event.target.value">
                        </div>
                    </div>

                    <!-- Nieuw wachtwoord -->
                    <div class="row row-cols-1 row-cols-md-2 position-relative my-3">
                        <div class="col">
                            <label for="newPassword" class="text-white fw-bold mt-2">Nieuw wachtwoord</label>
                        </div>
                        <div class="col">
                            <input type="password" id="newPassword" class="form-control" placeholder="Nieuw wachtwoord" :value="newPassword" @input="(event) => newPassword = event.target.value">
                        </div>
                    </div>

                    <!-- Herhaling nieuw wachtwoord -->
                    <div class="row row-cols-1 row-cols-md-2 position-relative my-3">
                        <div class="col">
                            <label for="newRepeatPassword" class="text-white fw-bold mt-2">Herhaling nieuw wachtwoord</label>
                        </div>
                        <div class="col">
                            <input type="password" id="newRepeatPassword" class="form-control" placeholder="Herhaling nieuw wachtwoord" :value="newPasswordRepeat" @input="(event) => newPasswordRepeat = event.target.value">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary"><i class="fa fa-lock me-1"></i> Wachtwoord wijzigen</button>
                        </div>
                    </div>
                </form>

                <hr/>
                
                <div class="d-md-flex d-block position-relative mb-2">
                    <h4 class="fw-bold text-white">2-staps authenticatie</h4>
                    <div class="btn btn-light float-right d-md-block d-none" @click="togglePreference" v-if="twoFactorOptions.length > 0"><i class="fa fa-key me-1"></i> Wijzig voorkeur</div>
                    <div class="btn btn-light d-md-none d-block" @click="togglePreference" v-if="twoFactorOptions.length > 0"><i class="fa fa-key me-1"></i> Wijzig voorkeur</div>
                </div>

                <!-- Instellen welke 2fa methode de voorkeur heeft -->
                <div id="preference" class="mb-3 position-relative">
                    <h5 class="text-white">Voorkeursmethode wijzigen</h5>
                    <div class="d-flex position-relative text-white">
                        <!-- SMS -->
                        <div class="d-block me-2">
                            <div :class="['btn btn-light smooth-opacity', preferred2fa === 'sms' ? '' : 'opacity-50']" @click="() => setPreferredAuthenticationMethod('sms')"><img src="@/assets/icons/phone.png" class="small-info me-1"> SMS</div>
                        </div>

                        <!-- Authenticator -->
                        <div class="d-block me-2">
                            <div :class="['btn btn-light smooth-opacity', preferred2fa === 'authenticator' ? '' : 'opacity-50']" @click="() => setPreferredAuthenticationMethod('authenticator')" v-if="setupAuthenticator"><img src="@/assets/icons/authenticator.png" class="small-info me-1"> Authenticator</div>
                        </div>

                        <!-- Yubikey voorkeur -->
                        <div class="d-block">
                            <div :class="['btn btn-light smooth-opacity', preferred2fa === 'yubikey' ? '' : 'opacity-50']" @click="() => setPreferredAuthenticationMethod('yubikey')" v-if="setupYubikey"><img src="@/assets/icons/yubikey.png" class="small-info me-1"> Yubikey</div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col">
                            <div class="btn btn-primary" @click="save2faPreference"><i class="fa fa-save me-1"></i> Opslaan</div>
                        </div>
                    </div>
                </div>

                <!-- SMS -->
                <div class="border-ur bg-trans p-2 position-relative">
                    <div class="icon-container-authentication d-flex">
                        <span class="fw-normal small p-1 px-2 mx-1 rounded-1 bg-success text-light">Ingesteld</span>
                        <img src="@/assets/icons/phone.png" class="icon">
                    </div>
                    <div class="d-flex">
                        <h4 class="fw-normal text-white">SMS</h4>
                        <span class="rounded-1 ms-2 bg-info text-dark p-1 d-block h-100 small m-0 preference" v-if="preferred2fa === 'sms'">
                            <span class="d-none d-md-block">Voorkeursmethode</span>
                            <span class="d-block d-md-none"><i class="fa fa-check"></i></span>
                        </span>
                    </div>
                    <p class="text-white">U ontvangt een code op uw mobiele apparaat wanneer u probeert in te loggen op uw account. Hierin staat een code die u moet invoeren tijdens het inlogproces.</p>
                    <p class="text-white fw-bold">
                        <template v-if="phonenumber">
                            De code wordt verstuurd naar <span class="rounded-1 bg-light text-dark p-1 mx-1">{{ phonenumber }}</span>.
                        </template>
                    </p>
                    <div class="mt-2">
                        <router-link class="btn btn-outline-light" to="/personalia">
                            <span class="fw-normal"><i class="fa fa-edit store me-1"></i> Wijzigen</span>
                        </router-link>
                    </div>
                </div>

                <!-- Authenticator -->
                <div class="border-ur bg-trans p-2 position-relative mt-3">
                    <div class="icon-container-authentication d-flex">
                        <span :class="['fw-normal small p-1 px-2 mx-1 rounded-1', setupAuthenticator ? 'bg-success text-light' : 'bg-warning text-dark']">{{ setupAuthenticator ? 'Ingesteld' : 'Niet ingesteld' }}</span>
                        <img src="@/assets/icons/authenticator.png" class="icon">
                    </div>
                    <div class="d-flex">
                        <h4 class="fw-normal text-white">Authenticator</h4>
                        <span class="rounded-1 ms-2 bg-info text-dark p-1 d-block h-100 small m-0 preference" v-if="preferred2fa === 'authenticator'">
                            <span class="d-none d-md-block">Voorkeursmethode</span>
                            <span class="d-block d-md-none"><i class="fa fa-check"></i></span>
                        </span>
                    </div>
                    <p class="text-white">Authenticator is een app voor uw mobiele telefoon. Het genereert getimede codes welke gebruikt worden tijdens het verificatieproces.</p>
                    <p class="text-white fw-bold mb-1">Download de Authenticator app</p>
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" class="btn btn-light me-1"><i class="fab fa-google-play me-1"></i> Play Store</a>
                    <a href="https://itunes.apple.com/us/app/-authenticator/id388497605?mt=8" target="_blank" class="btn btn-dark"><i class="fab fa-apple store me-1"></i> App Store</a>

                    <p class="text-white fw-bold mb-1 mt-3">Stel 2-staps authenticatie in met de  Authenticator app</p>
                    <div class="mt-2">
                        <router-link :class="['btn', setupAuthenticator ? 'btn-outline-light' : 'btn-primary']" to="/account/setup-authenticator">
                            <span class="fw-normal" v-if="!setupAuthenticator">
                                <i class="fa fa-plus store me-1"></i> Instellen
                            </span>
                            <span class="fw-normal" v-else>
                                <i class="fa fa-edit store me-1"></i> Wijzigen
                            </span>
                        </router-link>
                    </div>
                </div>

                <!-- Yubikey -->
                <div class="border-ur bg-trans p-2 mt-3 position-relative">
                    <div class="d-flex">
                        <h4 class="fw-normal text-white d-flex">Yubikey</h4>
                        <span class="rounded-1 ms-2 bg-info text-dark p-1 d-block h-100 small m-0 preference" v-if="preferred2fa === 'yubikey'">
                            <span class="d-none d-md-block">Voorkeursmethode</span>
                            <span class="d-block d-md-none"><i class="fa fa-check"></i></span>
                        </span>
                    </div>
                    <div class="icon-container-authentication d-flex">
                        <span :class="['fw-normal small p-1 px-2 mx-1 rounded-1', setupYubikey ? 'bg-success text-light' : 'bg-warning text-dark']">{{ setupYubikey ? 'Ingesteld' : 'Niet ingesteld' }}</span>
                        <img src="@/assets/icons/yubikey.png" class="icon">
                    </div>
                    <p class="text-white">Een Yubikey is een kleine USB-stick die multifactor authenticatie biedt met één druk op de knop. Yubikeys zijn sterk genoeg voor grote ondernemingen en tegelijkertijd simpel genoeg voor iedereen om te gebruiken.</p>

                    <a href="https://www.yubico.com/" target="_blank" class="btn btn-light me-1"><i class="fa fa-wallet me-1"></i> Yubikey aanschaffen</a>

                    <p class="text-white fw-bold mb-1 mt-3">Stel 2-staps authenticatie in met een Yubikey</p>
                    <div class="mt-2">
                        <router-link to="/account/setup-yubikey" :class="['btn', setupYubikey ? 'btn-outline-light' : 'btn-primary']">
                            <span class="fw-normal" v-if="!setupYubikey">
                                <i class="fa fa-plus store me-1"></i> Instellen
                            </span>
                            <span class="fw-normal" v-else>
                                <i class="fa fa-edit store me-1"></i> Wijzigen
                            </span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { setCustomer, isKnab, updateAppearanceKnab } from '@/App.vue';
import { useToast } from 'vue-toastification';

export default {
    name: "account-component",
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/account', 'Account')],
            currentPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
            setupAuthenticator: false,
            setupYubikey: false,
            preferred2fa: "",
            twoFactorOptions: [],
            phonenumber: null
        }
    },
    methods: {
        async submit() {
            await this.$http.post('/password/change', {
                password: this.currentPassword,
                new_password: this.newPassword,
                new_password_confirmation: this.newPasswordRepeat
            }).then((response) => {
                this.currentPassword = "";
                this.newPassword = "";
                this.newPasswordRepeat = "";
                const toast = useToast();
                toast.success(response.data["alert-success"]);
            }).catch((err) => {
                console.log(err);
            })    
        },
        setPreferredAuthenticationMethod(method) {
            this.preferred2fa = method;
        },
        async save2faPreference() {
            await this.$http.post('/my', {
                twofactorauthentication_method: this.preferred2fa,
            }).then((response) => {
                setCustomer(response.data)

                const toast = useToast();
                toast.success("Voorkeursmethode voor 2FA is aangepast");
                
                $("#preference").hide(400);
            }).catch((err) => {
                console.log(err);
            });
        },
        togglePreference() {
            $("#preference").toggle(400);
        }
    },
    async mounted() {
        if(isKnab()) {
            updateAppearanceKnab();
        }

        let customer;
        let response = await this.$http.get("/my");
        if(response) {
            customer = response.data;
            setCustomer(response.data)
            if(isKnab()) {
                $(".text-white").removeClass("text-white");
                $(".btn-dark, .btn-primary > span, .btn, .btn > span").removeClass("crowdsurance");
                $("a").removeClass("crowdsurance");
                $(".bg-success").removeClass("crowdsurance");
            }
        }

        if(customer) {
            this.setupAuthenticator = customer.authenticator_is_set_up;
            this.setupYubikey = customer.yubikey_is_set_up;
            this.preferred2fa = customer.twofactorauthentication_method;

            if(customer.phone_mobile)
                this.phonenumber = customer.phone_mobile;
            else if(customer.phone)
                this.phonenumber = customer.phone;
            else if(customer.business_phone_mobile)
                this.phonenumber = customer.business_phone_mobile;
            else if(customer.business_phone)
                this.phonenumber = customer.business_phone;

            if(this.setupAuthenticator)
                this.twoFactorOptions.push({title: 'Authenticator', value: 'authenticator'});
            if(this.setupYubikey)
                this.twoFactorOptions.push({title: 'Yubikey', value: 'yubikey'});

            $(`#preference2fa option[value=${this.preferred2fa}]`).attr('selected', 'selected');
        }

        // Laat alle objecten zachtjes infaden
        $(".fade-all").fadeOut(0);
        $(".fade-all").fadeIn('slow');

        $("#preference").hide();
    }
}
</script>

<style scoped>
hr {
    border-color: rgba(255, 255, 255, 0.2);
}

.preference {
    transform: translateY(1px);
}

.small-info {
    width: 1.3rem;
    transform: translateY(-2px);
}

.smooth-opacity {
    transition: opacity var(--transition-400ms) ease;
}

.icon {
    width: 40px;
    height: 40px;
    opacity: 1;
    position: relative;
}

.check-icon {
    width: 32px;
    height: 32px;
    opacity: 1;
    filter: invert();
    position: relative;
    transform: translateY(4px);

}

.large {
    color: #fff;
    font-size: 36px;
}

.icon-container-authentication {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 16;
    display: flex;
}

.border-ur bg-trans {
    border: 1px solid rgba(255, 255, 255, 0.25);
}
</style>