<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <!-- Artikel -->
            <div :class="['article', isLoadingArticle ? 'min-height-container' : '']">
                <h4 class="display-6 text-white fw-normal article_intro title">{{ currentArticle.title }}</h4>

                <div class="mb-2">
                    <mark class="article_intro text-white small position-relative">{{ $formatDate(currentArticle.publish_at) }}</mark>
                </div>

                <div :class="['position-relative title-container mb-2 article-title load-title-container', isLoadingArticle ? 'load-image' : '']">
                    <img :src="currentArticle.image_url" alt="Afbeelding bij kop" class="title-image" />
                    <!-- <div class="vignette"></div> -->
                </div>

                <div class="text-white w-100" v-html="currentArticle.article_intro" id="article_intro"></div>
                <hr/>
                <!-- Artikel inhoud -->
                <div class="text-white w-100" v-html="currentArticle.article_continuation" id="article_continuation"></div>

                <!-- Knop om terug te keren naar het nieuwsoverzicht -->
                <router-link to="/news" class="btn btn-primary"><i class="fa fa-arrow-left me-1"></i>Terug naar nieuws</router-link>
            </div>
            <hr>
        </div>
    </div>
</template>

<script type="text/javascript">
import { Breadcrumb, isKnab, updateAppearanceKnab } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import $ from 'jquery';

class Article {
    constructor(id, title, image_url, article_intro, article_continuation, publish_at) {
        this.id = id;
        this.title = title;
        this.image_url = image_url;
        this.article_intro = article_intro;
        this.article_continuation = article_continuation;
        this.publish_at = publish_at;
    }
}

export default {
    name: 'news-detail',
    components: {
        Breadcrumbs
    },
    data() {
        return {
            articles: [],
            currentArticle: new Article(),
            breadcrumbs: [new Breadcrumb("/news", "Nieuws")],
            isLoadingArticle: true,
        }
    },
    methods: {
        async getArticle() {
            const news_id = this.$route.params.id;
            await this.$http.get(`news_articles/${news_id}`)
                .then((response) => {
                    this.currentArticle = response.data;

                    let title = this.currentArticle.title;
                    if(title.length > 30)
                        title = title.substring(0, 30) + "...";

                    document.title = title + ` | ${ isKnab() ? "Mijn Knab Crowdsurance" : "Mijn Ondernemersvangnet" }`;

                    this.breadcrumbs.push(new Breadcrumb(`/news/${this.currentArticle.id}`, title));

                    setTimeout(() => {
                        $(".article_intro").fadeIn(800);
                        $(".title-image").fadeIn(600);
                        
                        if(this.currentArticle.image_url) {
                            $(".article-title").fadeIn(600);
                        } else {
                            $(".article-title-solo").fadeIn(600);
                        }
                    }, 200);

                    setTimeout(() => {
                        if(isKnab()) {
                            $(".text-light").removeClass("text-light");
                            $(".text-white").removeClass("text-white");
                        }
                        $("#article_continuation").fadeIn(800);
                        this.isLoadingArticle = false;
                    }, 600);
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        imgurl(filename) {
            /*
            let images = require.context('../assets/', false, /\.jpg$/);
            console.log(images, filename);
            return images(`${filename}`);
            */
           return "@assets/news_article_images" + filename;
        },
    },
    mounted() {
        if(isKnab()) {
            updateAppearanceKnab();
        }

        $(".article_intro").fadeOut(0);
        $("#article_continuation").fadeOut(0);
        $(".title-image").fadeOut(0);
        $(".title-container").fadeOut(0);
        $(".article-title-solo").fadeOut(0);
        this.getArticle();
    }
}
</script>

<style scoped>
hr {
    border-color: rgba(255, 255, 255, 0.2);
}

.load-title-container {
    transition: min-height 600ms ease;
}

.load-image {
    min-height: 300px !important;
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

mark {
    background-color: var(--dark-blue);
    color: #fff;
    border-radius: 4px;
    padding: 5px;
}

.title-container {
    border-radius: 3px;
    overflow: hidden;
    height: 300px !important;
}

.min-height-container {
    min-height: 100em !important;
}

.article {
    min-height: 0;
    transition: min-height 1s ease;
}

.img {
    width: 100%;
    position: relative;
    z-index: 32;
}

.title-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
}

.vignette {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 0 180px #000 inset;
}
</style>