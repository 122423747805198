<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="text-white display-4 fw-normal title">Mijn crowdsurance</h1>

            <div class="row mb-2">
                <div class="col mt-2">
                    <router-link to="products-crowdsurance/change" class="btn btn-primary btn-bg-light-blue float-md-end mb-2 mb-md-0">
                            <img src="@/assets/products_white_blue.svg" class="small-icon me-1">Mijn vangnetniveau wijzigen
                    </router-link>
                </div>
            </div>

            <div class="tab-line d-flex position-relative">
                <div v-for="(change, index) in changes" :key="index" :class="['btn btn-light tab float-start small', +change == +showChange ? '' : 'opacity-50']" @click="toggleChange(change)">
                    {{ +change == +now ? 'Huidig vangnetniveau' : $formatDate(change) }}
                </div>
            </div>

            <div id="warning" class="position-relative" v-if="+showChange != +now">
                <div class="bg-info text-dark small p-3 rounded-2 my-3 mb-2">
                    <i class="fa fa-exclamation me-1"></i>Let op! Deze gegevens gelden pas vanaf {{ $formatDate(showChange) }}.
                </div>
            </div>

            <template v-if="showAovSubgroup">
                <div class="mb-2">
                    <table class="table position-relative mt-3 new-brand" v-if="dynamic">
                        <thead>
                            <tr>
                                <th>
                                    Mijn vangnetniveau
                                </th>
                                <th>
                                    Inleg
                                </th>
                                <th>
                                    <span class="d-none d-md-block">Administratiekosten</span>
                                    <span class="d-md-none">Admin.</span>
                                </th>
                                <th class="text-end">Totaal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="subgroup in dynamic.aov_subgroups" :key="subgroup.id">
                                <tr :class="['d-none d-md-table-row current-brand-info not-current subgroup-info-' + subgroup.id]" v-if="showAovSubgroup.id == subgroup.id">
                                    <td class="bg-light p-3">
                                        <small>
                                            <div>Inkomen bij ziekte (2 jaar)</div>
                                            <h3 class="mt-2 h2">{{ $numberFormatEuro(subgroup?.subgroup_payout_monthly ?? 0, 0) }}</h3>
                                            <div>netto per maand</div>
                                        </small>
                                    </td>
                                    <td class="bg-light p-3">{{ $numberFormatEuro(subgroup?.expected_monthly_costs - subgroup?.monthly_costs ?? 0, 0) }} p/m</td>
                                    <td class="bg-light p-3">
                                        {{ $numberFormatEuro(subgroup?.monthly_costs ?? 0, 2) }} p/m<br/>
                                        <small class="small-italic">Inclusief BTW</small>
                                    </td>
                                    <td class="bg-light p-3 text-end fw-bold">
                                        {{ $numberFormatEuro(subgroup?.expected_monthly_costs ?? 0, 2) }} p/m</td>
                                </tr>
                                <tr :class="['d-table-row d-md-none current-brand-info not-current subgroup-info-' + subgroup.id]" v-if="showAovSubgroup.id == subgroup.id">
                                    <td colspan="4" class="bg-light p-3">
                                        <small>
                                            <div>Inclusief vangnetkosten</div>
                                            <div>Inkomen bij ziekte (2 jaar)</div>
                                            <h3 class="mt-2 h2">{{ $numberFormatEuro(subgroup?.subgroup_payout_monthly ?? 0, 0) }}</h3>
                                            <div>netto per maand</div>
                                        </small>
                                    </td>
                                </tr>
                                <tr :class="['d-table-row d-md-none current-brand-info not-current subgroup-info-' + subgroup.id]" v-if="showAovSubgroup.id == subgroup.id">
                                    <td class="p-3 small">
                                        &euro; per maand
                                    </td>
                                    <td class="p-3 small">
                                        {{ $numberFormatEuro(subgroup?.expected_monthly_costs - subgroup?.monthly_costs ?? 0, 0) }}
                                    </td>
                                    <td class="p-3 small">
                                        {{ $numberFormatEuro(subgroup?.monthly_costs ?? 0, 2) }}
                                    </td>
                                    <td class="fw-bold p-3 small text-end">
                                        {{ $numberFormatEuro(subgroup?.expected_monthly_costs ?? 0, 2) }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-else>
                <div class="position-relative">
                    <div class="bg-info text-dark small p-3 rounded-2 my-3 mb-2">
                        <i class="fa fa-exclamation me-1"></i>Er is nog geen vangnetniveau voor de gekozen datum.
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb, updateAppearanceKnab } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';

export default {
    name: 'crowdsurance-page',
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            dynamic: undefined,
            breadcrumbs: [new Breadcrumb('/products-crowdsurance', 'Mijn crowdsurance')],
            active_participants: [],
            now: new Date(new Date().setHours(0,0,0,0)),
            selected: new Date(new Date().setHours(0,0,0,0)),
            changes: [],
            showChange: null,
            
            showPaymentParticipant: null,
            showPaymentSubgroup: null,
            
            showAovParticipant: null,
            showAovSubgroup: null,
            
            showCovParticipant: null,
            showCovSubgroup: null,

            show_retirement_after_payment_amount: 0,
            show_retirement_after_directdebit_amount: 0,

            show_savings_after_directdebit_amount: 0,
            show_savings_during_payment_amount: 0,
        }
    },
    methods: {
        toggleChange(change) {
            this.showChange = change;
            
            this.showPaymentParticipant = null;
            this.showPaymentSubgroup = null;
            
            this.showAovParticipant = null;
            this.showAovSubgroup = null;
            
            this.showCovParticipant = null;
            this.showCovSubgroup = null;

            this.show_retirement_after_directdebit_amount = 0;
            this.show_retirement_after_payment_amount = 0;

            this.show_savings_after_directdebit_amount = 0;
            this.show_savings_during_payment_amount = 0;

            this.active_participants = [];

            this.dynamic.participants.forEach(function(participant) {
                const start_at = new Date(participant.start_at),
                    end_at = participant.end_at ? new Date(participant.end_at) : null;

                if(start_at <= change && (!end_at || end_at >= change)) {
                    this.active_participants.push(participant)

                    this.dynamic.payment_subgroups.forEach(function(subgroup) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showPaymentParticipant = participant;
                            this.showPaymentSubgroup = subgroup;

                            if(participant.active_payout_requests.length) {
                                participant.active_payout_requests.forEach(function(payout_request) {
                                    if(payout_request.type == 'payout_after_directdebit_sure' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_directdebit_amount = payout_request.amount;
                                    } else if(payout_request.type == 'payout_after_payment' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_payment_amount = payout_request.amount;
                                    } else if(payout_request.type == 'savings_during_payment' && payout_request.user_iban == null) {
                                        this.show_savings_during_payment_amount = payout_request.amount;
                                    }

                                }.bind(this));
                            }
                        }
                    }.bind(this));

                    this.dynamic.aov_subgroups.forEach(function(subgroup) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showAovParticipant = participant;
                            this.showAovSubgroup = subgroup;
                        }
                    }.bind(this));

                    this.dynamic.cov_subgroups.forEach(function(subgroup) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showCovParticipant = participant;
                            this.showCovSubgroup = subgroup;
                        }
                    }.bind(this));
                }
            }.bind(this));

            this.show_savings_after_directdebit_amount = (this.showPaymentSubgroup?.subgroup_payment_monthy ?? 0) - (this.showAovSubgroup?.expected_monthly_costs ?? 0) - (this.showCovSubgroup?.monthly_costs ?? 0) - this.show_retirement_after_directdebit_amount;
        },
    },
    async mounted() {
        updateAppearanceKnab();

        $(".tab-line").css("border-color", "#00000033");

        const response = await this.$http.get('/participants/dynamic');
        this.dynamic = response.data;

        this.now = new Date(new Date().setHours(0,0,0,0));

        // STAP 1 breakpoinst bepalen
        this.changes = [this.now];
        this.dynamic.participants.forEach(function(participant) {
            const start_at = new Date(participant.start_at),
                end_at = participant.end_at ? new Date(participant.end_at) : null;

            // opv start, in toekomst, of niet lege end
            if(start_at > this.now) {
                let change = new Date(start_at);
                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }
            if(end_at) {
                let change = new Date(end_at);
                change.setDate(change.getDate() + 1);

                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }

        }.bind(this));

        

        // STAP 2 huidig bepalen
        this.toggleChange(this.now);

        // STAP 3 als er nu geen product actief is (net nieuwe deelnemer bijv.) dan de deelnames per eerst volgende wijzigingen actief tonen
        if((!this.showAovSubgroup || !this.showPaymentSubgroup) && this.changes.map(Number).indexOf(+new Date(this.dynamic.change_at)) !== -1) {
            this.toggleChange(new Date(this.dynamic.change_at));
        }
    }
}
</script>

<style scoped>
.btn-bg-light-blue {
    border-color: #00B1C5 !important;
    background-color: #00B1C5 !important;
}

.btn-bg-light-blue:hover {
    border-color: #62b8c2 !important;
    background-color: #62b8c2 !important;
}
th{
    padding: 1em;
}
.current-brand-info > td {
    color: #002442 !important;
    background-color: #e8f4fa !important;
}
.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

.tab-line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.tab {
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    border: 0 !important;
    transition: opacity var(--transition-200ms) ease-in-out;
}

.tab:hover {
    background-color: #fff;
}

.invert {
    filter: invert() brightness(500%);
}
</style>