<template>
    <div class="d-flex breadcrumbs mb-2 position-relative">
        <div class="small text-light">
            <router-link class="back" to="/">Home</router-link>
        </div>

        <!-- Een lijst met alle breadcrumbs -->
        <div class="small text-light" v-for="(breadcrumb, index) in breadcrumbs" :key="index">
            <i class="fa fa-chevron-right me-1"></i> <router-link :to="breadcrumb.url" class="back">{{ breadcrumb.routeName }}</router-link>
        </div>
    </div>
</template>

<style scoped>
.breadcrumbs {
    gap: 7px;
}
</style>

<script type="text/javascript">
export default {
    name: "breadcrumbs-component",
    props: ["breadcrumbs"]
}
</script>