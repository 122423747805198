<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <div class="fab opacity-90 opacity-md-100" @click="saveData">
                <i class="fa fa-save"></i>
            </div>
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="text-white display-4 fw-normal title">NAW gegevens</h1>

            <hr/>

            <!-- E-mailadres, niet wijzigbaar door gebruiker -->
            <form id="personaliaForm" class="w-100" method="post">
                <div class="col">
                    <h3 class="text-white display-6 fw-normal">Persoonlijke gegevens</h3>
                </div>

                <!-- Naam -->
                <div class="row d-block">
                    <div class="col">
                        <h6 class="text-white">Naam</h6>
                    </div>
                    <div class="col mb-3">
                        <input type="text" class="form-control" placeholder="Roepnaam" name="firstname" v-model="firstname"
                            required>
                    </div>
                    <div class="col-5 mb-3">
                        <input type="text" class="form-control" placeholder="Tussenvoegsel" name="middlename"
                            v-model="middlename">
                    </div>
                    <div class="col mb-2">
                        <input type="text" class="form-control" placeholder="Achternaam" name="lastname" v-model="lastname"
                            required>
                    </div>
                </div>

                

                <div class="col">
                    <hr>
                </div>

                
                <div class="row">
                    <div class="col-5">
                        <h6 class="text-white mb-2">E-mailadres</h6>
                    </div>
                    <div class="col">
                        <p class="text-white mb-2 text-end">{{ email }}</p>
                        <input type="hidden" name="email">
                    </div>
                </div>


                <!-- Geboortedatum -->
                <div class="row m-0">
                    <div class="col m-0 p-0">
                        <h6 class="text-white m-0">Geboortedatum</h6>
                    </div>
                    <div class="col m-0 p-0">
                        <p class="text-white m-0 text-end">{{ birthdate ? convertDate(birthdate) : "" }}</p>
                    </div>
                </div>
                
                <div class="col">
                    <hr>
                </div>

                <div class="col">
                    <h3 class="text-white display-6 fw-normal">Bedrijfsgegevens</h3>
                </div>

                <!-- Organisatienaam -->
                <div class="col">
                    <h6 class="text-white">Organisatie naam</h6>
                </div>

                <div class="col mb-2">
                    <input type="text" class="form-control" placeholder="Organisatienaam" name="organisation_name" v-model="organisation_name">
                </div>
                

                <div class="col">
                    <hr>
                </div>

                <div class="col">
                    <h3 class="text-white display-6 fw-normal">Adres</h3>
                </div>

                <!-- Thuisadres -->
                <div class="row d-block">
                    <!-- Straatnaam -->
                    <div class="col">
                        <h6 class="text-white">Straat</h6>
                    </div>
                    <div class="col mb-2">
                        <input type="text" class="form-control" placeholder="Straatnaam" name="address_street"
                            v-model="address_street">
                    </div>

                    <!-- Huisnummer -->
                    <div class="row p-0 m-0">
                        <div class="col">
                            <div class="col">
                                <h6 class="text-white">Huisnummer</h6>
                            </div>
                            <div class="col mb-2">
                                <input type="text" class="form-control" placeholder="1" name="address_housenumber"
                                    v-model="address_housenumber">
                            </div>
                        </div>

                        <div class="col">
                            <div class="col">
                                <h6 class="text-white">Toevoeging</h6>
                            </div>
                            <div class="col mb-2">
                                <input type="text" class="form-control" placeholder="A" name="address_housenumber_addition"
                                    v-model="address_housenumber_addition">
                            </div>
                        </div>
                    </div>

                    <!-- Postcode -->
                    <div class="col">
                        <h6 class="text-white">Postcode</h6>
                    </div>
                    <div class="col-5 mb-2">
                        <input type="text" class="form-control" placeholder="1234AB" name="address_postalcode"
                            maxlength="16" v-model="address_postalcode">
                    </div>

                    <!-- Plaatsnaam -->
                    <div class="col">
                        <h6 class="text-white">Plaats</h6>
                    </div>
                    <div class="col mb-2">
                        <input type="text" class="form-control" name="address_city" v-model="address_city">
                    </div>


                    <div class="col">
                        <hr>
                    </div>
                    
                    <!-- Telefoonnummer privé -->
                    <div class="row m-0 p-0">
                        <div class="col">
                            <div class="col m-0 p-0">
                                <h6 class="text-white">Mobiele telefoon privé</h6>
                            </div>
                            <div class="col mb-2">
                                <div>
                                    <input type="tel" class="form-control" name="phone_mobile" v-model="phone_mobile">
                                </div>
                                <p class="very-small text-white">Je mobiele nummer wordt gebruikt voor <span style="border-bottom: 1px dotted #eee; cursor: context-menu" title="Two factor authenticatie is een methode om uw account verder te beveiligen. U ontvangt een SMS met daarin een code waarmee u kunt inloggen.">two-factor authenticatie</span></p>
                            </div>
                        </div>

                        <div class="col">
                            <div class="col m-0 p-0">
                                <h6 class="text-white">Telefoonnummer privé</h6>
                            </div>
                            <div class="col mb-2">
                                <input type="tel" class="form-control" name="phone" v-model="phone">
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <hr>
                    </div>
                    
                    <div v-if="display_business_data">
                        <div class="col">
                            <h3 class="text-white display-6 fw-normal">Zakelijke gegevens</h3>
                        </div>

                        <!-- Organisatienaam -->
                        <div class="col">
                            <h6 class="text-white">Organisatie naam</h6>
                        </div>
                        <div class="col mb-2">
                            <input type="text" class="form-control" placeholder="Organisatienaam" name="organisation_name" v-model="organisation_name">
                        </div>

                        <!-- KvK nummer -->
                        <div class="col mb-2">
                            <h6 class="text-white">KvK nummer</h6>
                        </div>
                        <div class="col mb-2">
                            <input type="text" class="form-control" placeholder="KvK nummer" name="organisation_coc" v-model="organisation_coc">
                        </div>

                        <!-- BTW nummer -->
                        <div class="col mb-2">
                            <h6 class="text-white">BTW nummer</h6>
                        </div>
                        <div class="col mb-4">
                            <input type="text" class="form-control" placeholder="BTW nummer" name="organisation_vat" v-model="organisation_vat">
                        </div>

                        <hr/>

                        <!-- Straatnaam -->
                        <div class="col">
                            <h6 class="text-white">Straat</h6>
                        </div>
                        <div class="col mb-2">
                            <input type="text" class="form-control" placeholder="Straatnaam" name="organisation_address_street"
                                v-model="organisation_address_street">
                        </div>

                        <!-- Huisnummer -->
                        <div class="row p-0 m-0">
                            <div class="col">
                                <div class="col">
                                    <h6 class="text-white">Huisnummer</h6>
                                </div>
                                <div class="col mb-2">
                                    <input type="text" class="form-control" placeholder="1" name="organisation_address_housenumber"
                                        v-model="organisation_address_housenumber">
                                </div>
                            </div>

                            <div class="col">
                                <div class="col">
                                    <h6 class="text-white">Toevoeging</h6>
                                </div>
                                <div class="col mb-2">
                                    <input type="text" class="form-control" placeholder="A" name="organisation_address_housenumber_addition"
                                        v-model="organisation_address_housenumber_addition">
                                </div>
                            </div>
                        </div>

                        <!-- Postcode -->
                        <div class="col">
                            <h6 class="text-white">Postcode</h6>
                        </div>
                        <div class="col-5 mb-2">
                            <input type="text" class="form-control" placeholder="1234AB" name="organisation_address_postalcode"
                                maxlength="16" v-model="organisation_address_postalcode">
                        </div>

                        <!-- Plaatsnaam -->
                        <div class="col">
                            <h6 class="text-white">Plaats</h6>
                        </div>
                        <div class="col mb-2">
                            <input type="text" class="form-control" name="organisation_address_city" v-model="organisation_address_city">
                        </div>

                        <!-- Zakelijke contactgegevens -->
                        <div class="row m-0 p-0">
                            <div class="col">
                                <div class="col m-0 p-0">
                                    <h6 class="text-white">Telefoonnummer zakelijk</h6>
                                </div>
                                <div class="col mb-2">
                                    <input type="tel" class="form-control" name="business_phone" v-model="business_phone">
                                </div>
                            </div>

                            <div class="col">
                                <div class="col m-0 p-0">
                                    <h6 class="text-white">Mobiele telefoon zakelijk</h6>
                                </div>
                                <div class="col mb-2">
                                    <input type="tel" class="form-control" name="business_phone_mobile" v-model="business_phone_mobile">
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <hr>
                        </div>
                    </div>

                    <!-- Financiele gegevens -->
                    <div class="col">
                        <h3 class="text-white display-6 fw-normal">Financi&euml;le gegevens</h3>
                    </div>

                    <!-- IBAN incasso's aanleg -->
                    <div class="row m-0 d-block">
                        <div class="col m-0 p-0">
                            <h6 class="text-white mb-2">IBAN incasso's inleg</h6>
                        </div>
                        <div class="col m-0 p-0">
                            <p class="text-white mb-2 tl">
                                {{ iban_payment || "-" }}
                            </p>
                        </div>
                    </div>

                    <!-- IBAN pensioenspaarrekening -->
                    <div class="row m-0 d-block" v-if="!isCrowdsurance()">
                        <div class="col m-0 p-0">
                            <h6 class="text-white mb-2">IBAN pensioenspaarrekening</h6>
                        </div>
                        <div class="col m-0 p-0">
                            <p v-if="iban_retirement" class="text-white mb-2 tl">
                                {{ iban_retirement }}
                            </p>
                            <router-link to="/retirement-account" v-else class="btn btn-light"><i class="fa fa-link"></i> IBAN pensioenspaarrekening aanvragen</router-link>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <div class="btn btn-primary" @click="saveData"><i class="fa fa-save me-1"></i> Opslaan</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>
h6 {
    font-weight: 800;
}

.very-small {
    font-size: 12px;
    margin-top: 4px;
}

.tl {
    text-align: left !important;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
    text-align: left;
}

.fab {
    position: fixed;
    right: 1vw;
    bottom: 1vw;
    padding: 0.5em;
    font-size: 48px;
    background-color: var(--dark-blue);
    color: #fff;
    border-radius: 50%;
    z-index: 9999;
    border: 3px solid var(--dark-blue);
    transition: background-color 100ms ease, color 100ms ease;
}

.fab:hover {
    background-color: #fff;
    color: var(--dark-blue);
    cursor: pointer;
}

.crowdsurance > .fab {
    background-color: #00a8ba;
    border: 3px solid #00a8ba;
}
div.crowdsurance > i {
    color: #fff !important;
}
.crowdsurance > .fab:hover {
    background-color: #fff;
    border: 3px solid #00a8ba;
}
div.crowdsurance:hover > i {
    color: #00a8ba !important;
}
</style>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb, setCustomer, isKnab, updateAppearanceKnab } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { useToast } from 'vue-toastification';

export default {
    name: "personalia-component",
    components: {
        Breadcrumbs
    },
    data() {
        return {
            email: "",
            firstname: "",
            middlename: "",
            lastname: "",
            birthdate: Date.now(),
            address_city: "",
            address_country: "",
            address_housenumber: "",
            address_housenumber_addition: "",
            address_postalcode: "",
            address_province: "",
            address_street: "",
            phone: "",
            phone_mobile: "",
            private_address: "",
            iban_payment: null,
            iban_retirement: null,
            organisation_address_country: "",
            organisation_address_street: "",
            organisation_address_housenumber: "",
            organisation_address_housenumber_addition: "",
            organisation_address_postalcode: "",
            organisation_address_city: "",
            business_email: "",
            business_phone: "",
            business_phone_mobile: "",
            organisation_name: "",
            organisation_vat: "",
            organisation_coc: "",

            display_business_data: false,
            
            current_brand_id: undefined,

            breadcrumbs: [new Breadcrumb('/personalia', 'NAW gegevens')]
        }
    },
    mounted() {
        if(isKnab()) {
            updateAppearanceKnab();
            $(".btn-primary").removeClass("crowdsurance");
        }
        $("#personaliaForm").hide();
        this.getData();
    },
    methods: {
        async getData() {
            const response = await this.$http.get('/my');
            if (response) {
                setCustomer(response.data)
            }

            let customer = response.data;

            // Persoonsgegevens
            this.firstname = customer.firstname;
            this.middlename = customer.middlename;
            this.lastname = customer.lastname;
            this.birthdate = customer.born_at;
            this.email = customer.email;

            this.address_street = customer.address_street;
            this.address_housenumber = customer.address_housenumber;
            this.address_housenumber_addition = customer.address_housenumber_addition;
            this.address_postalcode = customer.address_postalcode;
            this.address_city = customer.address_city;

            this.phone = customer.phone;
            this.phone_mobile = customer.phone_mobile;

            this.iban_payment = customer.iban_payment;
            this.iban_retirement = customer.iban_retirement;

            this.organisation_name = customer.organisation_name;
            this.organisation_vat = customer.organisation_vat,
            this.organisation_coc = customer.organisation_coc,

            this.organisation_address_country = customer.organisation_address_country;
            this.organisation_address_street = customer.organisation_address_street;
            this.organisation_address_housenumber = customer.organisation_address_housenumber;
            this.organisation_address_housenumber_addition = customer.organisation_address_housenumber_addition;
            this.organisation_address_postalcode = customer.organisation_address_postalcode;
            this.organisation_address_city = customer.organisation_address_city;

            this.business_phone = customer.business_phone;
            this.business_phone_mobile = customer.business_phone_mobile;

            this.current_brand_id = customer.current_brand_id;

            $("#personaliaForm").show("slow");
        },
        isCrowdsurance() {
            // Is nodig omdat ik deze niet buiten de context mag opvragen *zucht*
            return isKnab();
        },
        async saveData() {
            await this.$http.post('/my', {
                firstname: this.firstname,
                middlename: this.middlename,
                lastname: this.lastname,
                address_street: this.address_street,
                address_housenumber: this.address_housenumber,
                address_housenumber_addition: this.address_housenumber_addition,
                address_postalcode: this.address_postalcode,
                address_city: this.address_city,
                phone: this.phone,
                phone_mobile: this.phone_mobile,

                organisation_name: this.organisation_name,
                organisation_coc: this.organisation_coc,
                organisation_vat: this.organisation_vat,
                organisation_address_country: this.organisation_address_country,
                organisation_address_street: this.organisation_address_street,
                organisation_address_housenumber: this.organisation_address_housenumber,
                organisation_address_housenumber_addition: this.organisation_address_housenumber_addition,
                organisation_address_postalcode: this.organisation_address_postalcode,
                organisation_address_city: this.organisation_address_city,

                business_phone: this.business_phone,
                business_phone_mobile: this.business_phone_mobile
            }).then((response) => {
                setCustomer(response.data);

                const toast = useToast();
                toast.success("Je gegevens zijn bijgewerkt");
                document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
            });

        },
        convertDate(date) {
            let d = new Date(date);
            return `${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}-${(parseInt(d.getMonth()) + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}-${d.getFullYear()}`;
        }
    }
}
</script>