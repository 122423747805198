<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="text-white display-4 fw-normal title">Administratieoverzicht</h1>

            <hr />

            <!-- <p class="bg-light p-3 rounded-2 small text-dark">
                <i class="fa fa-info-circle"></i> In dit overzicht zie je facturen en rekeningoverzichten. Het rekeningoverzicht geeft inzicht in de verdeling waar je inleg aan is besteed. De facturen kun je gebruiken voor je boekhouding.
            </p> -->

            <div id="openDirectDebits">
                <h3 class="text-white display-6 fw-normal">Openstaande betalingen</h3>

                <p class="bg-warning p-3 rounded-2 small text-dark">
                    <i class="fa fa-exclamation me-1"></i>
                    Uit controle is gebleken dat jouw inleg voor het vangnet één of meerdere maanden niet kon worden geïncasseerd.
                </p>

                <div class="allInvoices position-relative col w-100">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Datum</th>
                                <th>Storneringsreden</th>
                                <th class="text-center">PDF</th>
                                <!-- <th></th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="open_directdebit in openDirectDebits" :key="open_directdebit.id" class="position-relative failed-invoice" @click="() => downloadInvoice(open_directdebit.invoice)">
                                <td class="text-danger">{{ $formatDate(open_directdebit.failed_at) }}</td>
                                <td class="text-danger">{{ open_directdebit.last_assigned_banktransaction.reason_return }}</td>
                                <template v-if="open_directdebit.invoice">
                                    <td class="text-center text-danger"><i :class="'fa fa-file-pdf invoice-' + open_directdebit.invoice.id" title="Download factuur als PDF"></i> </td>
                                </template>
                                <template v-else>
                                    <td class="text-center text-danger"></td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col">
                        <router-link to="/open-directdebits" class="btn btn-danger"><i class="fa fa-exclamation-circle"></i> Bekijk openstaande inleg</router-link>
                    </div>
                </div>
                <hr class="mt-4" />
            </div>

            <!-- Toon hier alle facturen -->
            <div id="invoices" class="position-relative">
                <h3 class="text-white display-6 fw-normal" v-if="openDirectDebits.length > 0">Overzicht</h3>
                <div class="btn btn-primary mb-2" @click="toggleFilters"><i class="fa fa-filter me-1"></i> Filters <div class="notif-smaller filterCount">{{ filterCounter }}</div></div>

                <div class="filter-view mb-2 w-100">
                    <div class="p-3 border semi-trans rounded-3">
                        <div class="row my-1">
                            <div class="col">
                                <select class="form-select" id="filterType" @change="setFilterCounter">
                                    <option value="" selected>-- Kies een factuurtype --</option>
                                    <option v-for="[type, name] in Object.entries(invoiceTypes)" :value="type" :key="type">
                                    {{ name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <hr>

                        <!-- Periode -->
                        <div class="row row-cols-2 mb-1">
                            <div class="col text-white fw-bold">
                                Startperiode
                            </div>
                            <div class="col text-white fw-bold">
                                Eindperiode
                            </div>
                        </div>

                        <div class="row row-cols-2 mb-1">
                            <div class="col">
                                <input type="date" class="form-control" id="filterDateStart" name="startDate" :value="filterDateStart" @change="setFilterCounter" @input="(event) => filterDateStart = event.target.value">
                            </div>
                            <div class="col text-white fw-bold">
                                <input type="date" class="form-control" id="filterDateEnd" name="endDate" :value="filterDateEnd" @change="setFilterCounter" @input="(event) => filterDateEnd = event.target.value">
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <button class="btn btn-primary me-2" @click="() => fetchFilteredInvoices()"><i class="fa fa-filter me-1"></i> Filteren</button>
                                <button class="btn btn-danger filterCount" @click="removeFilters"><i class="fa fa-trash me-1"></i> Reset filters</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Facturen -->
                <div class="allInvoices col">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Datum</th>
                                <th>Type</th>
                                <th></th>
                                <th class="text-center">PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invoice in invoices" :key="invoice.id" :class="position-relative" @click="() => downloadInvoice(invoice)">
                                <td :class="['position-relative', invoice.isNew ? 'fw-bold' : '']">{{ $formatDate(invoice.invoice_at) }}</td>
                                <td :class="['text-start', invoice.isNew ? 'fw-bold' : '']">
                                    {{ invoiceTypes[invoice.type] }}
                                </td>
                                <td :class="['text-start', invoice.isNew ? 'fw-bold' : '']">
                                    <img src="@/assets/nieuw.svg" v-if="invoice.isNew" class="icon-new" title="Nieuw">
                                </td>
                                <td class="text-center"><i :class="'fa fa-file-pdf invoice-' + invoice.id" title="Download factuur als PDF"></i> </td>

                                <!-- Indicator voor nieuwe facturen -->
                                <!-- <div class="notif-new" v-if="invoice.isNew"></div> -->
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Pagination -->
                <Pagination :pagination="invoicesPagination" :currentPaginationPage="currentPaginationPage" @updatePagination="(url, label) => fetchFilteredInvoices(url, label)"/>
            </div>

            <!-- Melding dat er geen facturen zijn -->
            <div id="noInvoices">
                <p class="p-3 rounded-2 bg-info text-dark small mt-2"><i class="fa fa-info-circle me-1"></i> Er zijn geen documenten gevonden.</p>
            </div>

            <div class="mt-3">
                <h3 class="text-white display-6 fw-normal">Opvragen jaaropgaven</h3>
                <div class="input-group mt-3">
                    <input type="number" min="2020" :max="new Date().getFullYear()" class="form-control" v-model="annualStatementYear">
                    <button class="btn btn-primary position-relative" @click="downloadAnnualStatement()"><i class="fa fa-download me-1"></i> Download jaaropgave</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.notif-smaller {
    right: -12px;
    top: -8px;
}

.semi-trans {
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.notif-new {
    position:absolute;
    left: -5px;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    background-color: var(--warning);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
    text-align: left;
}

.fa-file-pdf {
    cursor: pointer;
}

table tr td,
table tr,
th {
    background-color: transparent !important;
    color: #fff;
}

.crowdsurance table tr td,
.crowdsurance table tr,
.crowdsurance th {
    background-color: transparent !important;
    color: var(--crowdsurance-header);
}

tr {
    cursor: pointer;
}

.failed-invoice {
    /* background-color: rgba(255, 16, 32, 0.2) !important; */
    color: #990000 !important;
}

.success-invoice {
    background-color: rgba(16, 204, 255, 0.2) !important;
}
</style>

<script type="text/javascript">
/*eslint-disable*/

import $ from 'jquery';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Pagination from '@/components/PaginationComponent.vue';
import { Breadcrumb, getCustomer, updateAppearanceKnab, isKnab } from '@/App.vue';
import { saveAs } from 'file-saver';
import { useToast } from 'vue-toastification';

export default {
    name: "invoices-component",
    components: {
        Breadcrumbs,
        Pagination
    },
    data() {
        return {
            invoices: [],
            current_brand_id: undefined,
            openDirectDebits: [],
            breadcrumbs: [new Breadcrumb("/invoices", "Administratieoverzicht")],
            invoiceTypes: {
                'payment': 'Rekeningoverzicht',
                'subscription_costs': 'Factuur kosten',
                'startup_costs': 'Factuur inschrijfgeld',
                'payout_request': 'Uitbetalingsverzoek',
                'credit': 'Handmatig',
            },
            listStart: 0,
            invoicesPagination: [],
            annualStatementYear: (new Date()).getFullYear() - 1,
            downloadingInvoice: false,
            currentPaginationPage: "1",

            filterDateStart: undefined,
            filterDateEnd: undefined,

            filterCounter: 0,
            current_brand_id: undefined,
        }
    },
    methods: {
        toggleFilters() {
            $(".filter-view").toggle(400);
        },
        removeFilters() {
            this.filterCounter = 0;
            this.filterDateStart = undefined;
            this.filterDateEnd = undefined;
            $("#filterType").val("");
            $(".filterCount").hide(400);
            this.fetchFilteredInvoices();
        },
        async fetchMyOpenDirectdebits() {
            try {
                const open_directdebits = await this.$http.get('open_directdebits')
                if(!open_directdebits)
                    return;

                let data = [];
                open_directdebits.data.forEach(function(open_directdebit) {
                    if(open_directdebit.last_mollie_payment?.status !== 'paid') {
                        data.push(open_directdebit)
                    }
                })
                this.openDirectDebits = data;
                if(data.length) {
                    $("#openDirectDebits").show();
                } else {
                    $("#openDirectDebits").hide();
                }

                setTimeout(function() {
                    if(isKnab()) {
                        $(".text-white").removeClass("text-white");
                        updateAppearanceKnab();
                    }
                }, 200);

            } catch (error) {
                const toast = useToast();
                toast.error("We kunnen je facturen momenteel niet ophalen. Probeer het later nog eens", {
                    icon: "fa fa-frown"
                });
                console.error(error)
            }
        },
        async fetchFilteredInvoices(url = "", label = "") {
            let filterType = $("#filterType").find(":selected").val();
            let query = "";

            if(filterType !== "") {
                query += `filter_type=${filterType}`;
            }

            if(this.filterDateStart) {
                if(query === "") {
                    query += `filter_invoice_at_from=${this.filterDateStart}`;
                } else {
                    query += `&filter_invoice_at_from=${this.filterDateStart}`;
                }
            }

            if(this.filterDateEnd) {
                if(query === "") {
                    query += `filter_invoice_at_to=${this.filterDateEnd}`;
                } else {
                    query += `&filter_invoice_at_to=${this.filterDateEnd}`;
                }
            }

            if(!this.$canUsePagination(url, label))
                return;

            const httpUrl = url === "" ? `invoices?${query}` : url;
            try {
                await this.$http.get(httpUrl)
                .then((response) => {
                    this.invoices = response.data.data;
                    this.invoicesPagination = response.data.links;

                    // Verander huidige pagina voor pagination
                    this.currentPaginationPage = response.data.current_page.toString();

                    if(this.invoices.length === 0) {
                        $(".invoices").hide(600);
                        $("#noInvoices").show(600);
                    } else {
                        $(".invoices").show(400);
                        $("#noInvoices").hide(600);
                    }

                    this.invoicesPagination[0].label = "<";
                    this.invoicesPagination[this.invoicesPagination.length - 1].label = ">";
                });

                if(isKnab()) {
                    $(".text-white").removeClass("text-white");
                    updateAppearanceKnab();
                }
            } catch(error) {
                console.error(error);
            }
        },
        setFilterCounter() {
            let count = 0;
            let filterType = $("#filterType").find(":selected").val();
            if(filterType !== "")
                count++;
            if(this.filterDateStart)
                count++;
            if(this.filterDateEnd)
                count++;

            if(count > 0)
                $(".filterCount").show(400);
            else
                $(".filterCount").hide(400);
            this.filterCounter = count;
        },
        async fetchMyInvoices() {
            try {
                await this.$http.get('invoices').
                    then((response) => {
                        const data = response.data;
                        this.invoices = data.data;
                        this.invoicesPagination = data.links;

                        if(!this.invoices.length)
                            $("#noInvoices").show(400);
                        else {
                            $("#invoices").show(400);
                            setTimeout(() => {
                                $(".allInvoices").show(400);
                            }, 400);
                        }

                        this.invoicesPagination[0].label = "<";
                        this.invoicesPagination[this.invoicesPagination.length - 1].label = ">";
                    });
                if(isKnab()) {
                    $(".text-white").removeClass("text-white");
                    updateAppearanceKnab();
                }
            } catch (error) {
                console.error(error.response?.data?.errors);
                console.error(error);
            }
        },
        async fetchPaginatedInvoices(url) {
            await this.$http.get(url)
                .then((response) => {
                    this.invoices = response.data.data;
                    this.invoicesPagination = response.data.links;
                    $(".allInvoices").show(400);
                })
                .catch((err) => {
                    const toast = useToast();
                    toast.error("We kunnen je facturen momenteel niet ophalen. Probeer het later nog eens", {
                        icon: "fa fa-frown"
                    });
                    console.log(err);
                });

            if(isKnab()) {
                $(".text-white").removeClass("text-white");
                updateAppearanceKnab();
            }
        },
        downloadInvoice(invoice) {
            if(!this.downloadingInvoice && invoice) {
                this.downloadingInvoice = true;
                $('.invoice-' + invoice.id).addClass('fa-spinner fa-spin').removeClass('fa-file-pdf');
                try {
                    this.$http.get('invoices/download/' + invoice.id, {responseType: 'blob'})
                    .then(response => {
                        saveAs(response.data, 'download_' + invoice.number + '.pdf');
                        this.downloadingInvoice = false;

                        setTimeout(function() {
                            $('.invoice-' + invoice.id).addClass('fa-file-pdf').removeClass('fa-spinner fa-spin');
                        }, 1000);
                    })
                } catch (error) {
                    console.error(error.response?.data?.errors);
                    console.error(error);
                }
            }
        },
        downloadAnnualStatement() {
            // todo jaar kiezen in interface
            const toast = useToast();

            let year = this.annualStatementYear;

            // Afvangen dat de gebruiker het type van het input element heeft veranderd en iets anders invoert
            if(isNaN(year)) {
                toast.error("Voer een jaartal in om de jaaropgave te downloaden.", {
                    icon: "fa fa-times"
                });
                return;
            }

            // Kan geen jaaropgaven downloaden uit de toekomst of van voor 2020
            let currentYear = new Date().getFullYear();
            if(parseInt(year) > currentYear || parseInt(year) < 2020) {
                toast.error("Voer een geldig jaartal in om de jaaropgave te downloaden.", {
                    icon: "fa fa-times"
                });
                return;
            }

            try {
                this.$http.get('annual_statement/' + year, {responseType: 'blob'})
                .then(response => {
                    saveAs(response.data, 'annual_statement_' + year + '.pdf');
                    if(isKnab()) {
                        $(".text-white").removeClass("text-white");
                        updateAppearanceKnab();
                    }
                })
            } catch (error) {
                console.error(error.response?.data?.errors);
                console.error(error);
            }
        }
    },
    async mounted() {
        let customer = getCustomer();
        if(customer) {
            this.current_brand_id = customer.current_brand_id;
            if(isKnab()) {
                updateAppearanceKnab();
            }
        }
        $("#openDirectDebits").hide();

        $("#invoices").hide();
        $("#noInvoices").hide();
        $(".filterCount").hide();

        $(".filter-view").hide();
        $(".allInvoices").hide();

        await this.fetchMyOpenDirectdebits();
        await this.fetchMyInvoices();

        this.$watch("downloadingInvoice", (value) => {
            this.downloadingInvoice = value;
        });

        this.current_brand_id = getCustomer()?.current_brand_id;
    }
}
</script>